import React from 'react';
import Modal from '../../Modal';
import { Container, Row, Col } from '../../Grid';
import Button from '../../Button';
import { gaPush } from 'Utils/ga_push';
import styles from './styles.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind({ ...styles });

type State = {
  errors: { [string]: ?string },
  loading: boolean,
};

type Props = {
  isOpen: () => void,
  onClose: () => void,
  flyout: () => void,
  leadtracking: any,
};

export class HintUsedCarValuation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      modalOpened: false,
    };
  }

  componentDidUpdate(_, prevState) {
    const { modalOpened } = this.state;

    if (modalOpened !== prevState.modalOpened && modalOpened) {
      gaPush({
        event: 'gaEvent',
        category: 'modalOpen',
        action: 'modal click',
        label: 'true',
      });
    }
  }

  closeAction = () => {
    const { onClose } = this.props;
    if (onClose) {
      gaPush({
        event: 'gaEvent',
        category: 'modalOpen',
        action: 'modal click',
        label: 'false',
      });
    }
    this.setState({ modalOpened: false });
    onClose();
  };

  openLink = () => {
    const { flyout } = this.props;
    flyout();
    window.location.href = '/gebrauchtwagenbewertung/';
  };

  render() {
    const { isOpen, leadtracking } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        onClose={this.closeAction}
        className={styles.contactmodal}
        onOpened={() => {
          this.setState({ modalOpened: true });
        }}
      >
        <Container>
          <h2 className={styles.headline}>Gebrauchtwagenbewertung</h2>
          <Row>
            <Col cols={12} className={cx('content', 'contentPt')}>
              Hier gelangen Sie zur Gebrauchtwagenbewertung von mazda.de. Die
              Gebrauchtwagenbewertung von mazda.de wird technisch direkt auf der Händlerwebseite
              dargestellt. Hierbei erteilte Zustimmungen für den Einsatz von Cookies und ähnlichen
              Technologien gelten entsprechend.
            </Col>
          </Row>
          <Row>
            <Col cols={12} className={cx('content', 'contentPt', 'contentPb')}>
              <Button
                label="zur Bewertung"
                target="_blank"
                trackingLabel="Gebrauchtwagenbewertung"
                onClick={this.openLink}
                additionalProps={leadtracking}
              />
            </Col>
          </Row>
        </Container>
      </Modal>
    );
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export class Container extends Component {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.node,
  };

  render() {
    const { className, style, children } = this.props;

    return (
      <div style={style} className={cx('container', className)}>
        {children}
      </div>
    );
  }
}

export class Row extends Component {
  static propTypes = {
    children: PropTypes.node,
    align: PropTypes.oneOf(['left', 'center', 'right']),
    full: PropTypes.bool,
    className: PropTypes.string,
    e2e: PropTypes.string,
  };

  render() {
    const { align, full, e2e } = this.props;

    const rowClass = cx('row', this.props.className, {
      [`row--align-${align}`]: align,
      'row--full': full,
    });

    return (
      <div className={rowClass} data-e2e={e2e}>
        {this.props.children}
      </div>
    );
  }
}

export class Col extends Component {
  static propTypes = {
    cols: PropTypes.number,
    xs: PropTypes.number,
    s: PropTypes.number,
    m: PropTypes.number,
    l: PropTypes.number,
    xl: PropTypes.number,
    align: PropTypes.string,
    valign: PropTypes.oneOf(['center', 'bottom']),
    children: PropTypes.node,
    className: PropTypes.string,
    stretch: PropTypes.bool,
    hidden: PropTypes.bool,
    e2e: PropTypes.string,
  };

  static defaultProps = {
    cols: 12,
  };

  render() {
    const { cols, xs, s, m, l, xl, align, valign, className, stretch, hidden, e2e } = this.props;

    const colClass = cx(`gr-${cols}`, className, {
      [`gr-${xs}@xs`]: xs,
      [`gr-${s}@s`]: s,
      [`gr-${m}@m`]: m,
      [`gr-${l}@l`]: l,
      [`gr-${xl}@xl`]: xl,
      [`gr--align-${align}`]: align,
      [`gr--valign-${valign}`]: valign,
      'gr--stretch': stretch,
      hidden,
    });

    return (
      <div className={cx(colClass)} data-e2e={e2e}>
        {this.props.children}
      </div>
    );
  }
}

exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@keyframes _2vw3GLgMMm6VMMtW0M-w5n{from{transform:translate3d(0, 1rem, 0);visibility:visible;opacity:0}to{transform:translate3d(0, 0, 0);opacity:1}}@keyframes _19VwB2mnHgwWX4C9c8z_ze{from{visibility:visible;opacity:0}to{opacity:1}}@keyframes RIEMb8AmN68Q_Ue_Hvyca{0%{filter:blur(3.125rem)}25%{filter:blur(1.25rem)}100%{filter:blur(0)}}.ItcJuPTfq6PNi9Q9hemc2{display:flex}.ItcJuPTfq6PNi9Q9hemc2>sup{position:static;display:block;flex-shrink:0;width:2.5rem;line-height:normal}@media screen and (min-width: 320px){.ItcJuPTfq6PNi9Q9hemc2:not(:last-child){margin-bottom:calc(1rem * 1)}}@media screen and (min-width: 768px){.ItcJuPTfq6PNi9Q9hemc2:not(:last-child){margin-bottom:calc(2rem * 1)}}\n", ""]);

// exports
exports.locals = {
	"footnote": "ItcJuPTfq6PNi9Q9hemc2",
	"slideinup": "_2vw3GLgMMm6VMMtW0M-w5n",
	"fadein": "_19VwB2mnHgwWX4C9c8z_ze",
	"blur": "RIEMb8AmN68Q_Ue_Hvyca"
};
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@keyframes _3ApGk0VYmChN3r_WBAWVPg{from{transform:translate3d(0, 1rem, 0);visibility:visible;opacity:0}to{transform:translate3d(0, 0, 0);opacity:1}}@keyframes _3dhW78H6MkdcE0Km33oKW0{from{visibility:visible;opacity:0}to{opacity:1}}@keyframes wYZ3dvJ_cwYtiWi4LWdNp{0%{filter:blur(3.125rem)}25%{filter:blur(1.25rem)}100%{filter:blur(0)}}@media screen and (min-width: 320px){._1rMMVloRkHxoXGwiNQyWo4{padding-top:calc(1rem * 1);padding-bottom:calc(1rem * 2)}}@media screen and (min-width: 768px){._1rMMVloRkHxoXGwiNQyWo4{padding-top:calc(2rem * 1);padding-bottom:calc(2rem * 2)}}._1rMMVloRkHxoXGwiNQyWo4 p+p{margin-top:1.25rem}._1rMMVloRkHxoXGwiNQyWo4 ._1lHKwoCI3ZNrqQ9sbin3_x,._1rMMVloRkHxoXGwiNQyWo4 ._1lHKwoCI3ZNrqQ9sbin3_x>p{text-align:center;font-size:1.25rem}@media screen and (min-width: 320px){._1rMMVloRkHxoXGwiNQyWo4 ._1lHKwoCI3ZNrqQ9sbin3_x,._1rMMVloRkHxoXGwiNQyWo4 ._1lHKwoCI3ZNrqQ9sbin3_x>p{margin-bottom:calc(1rem * 2)}}@media screen and (min-width: 768px){._1rMMVloRkHxoXGwiNQyWo4 ._1lHKwoCI3ZNrqQ9sbin3_x,._1rMMVloRkHxoXGwiNQyWo4 ._1lHKwoCI3ZNrqQ9sbin3_x>p{margin-bottom:calc(2rem * 2)}}._1rMMVloRkHxoXGwiNQyWo4 ._2L4dprglJ5kYQyefIDpKLk>p{color:#fff}\n", ""]);

// exports
exports.locals = {
	"contentText": "_1rMMVloRkHxoXGwiNQyWo4",
	"headline": "_1lHKwoCI3ZNrqQ9sbin3_x",
	"overwritep": "_2L4dprglJ5kYQyefIDpKLk",
	"slideinup": "_3ApGk0VYmChN3r_WBAWVPg",
	"fadein": "_3dhW78H6MkdcE0Km33oKW0",
	"blur": "wYZ3dvJ_cwYtiWi4LWdNp"
};
// @flow
import React, { Component, Fragment } from 'react';
import cn from 'classnames';
import data from './cookieData.json';
import ContentText from 'Components/ContentText';
import Table from 'Components/Table';
import { getGivenBackground } from 'Utils/getColor';
import styles from './styles.scss';

type Props = {
  background: string,
  anchor: string,
  type: 'notwendig' | 'statistik' | 'marketing' | 'komfort',
  children?: Node,
};

const getTitle = (title: string) => {
  if (title !== 'notwendig') {
    return title;
  }

  return `${title}e`;
};

export default class CookieTable extends Component<Props> {
  additionalInformation = () => {
    if (this.props.type === 'komfort') {
      return (
        <p>
          Aufrufe an die Google Maps APIs ({' '}
          <a className={styles.anchor} href="https://maps.googleapis.com" data-anchor>
            https://maps.googleapis.com
          </a>
          ) dienen zur Einbindung der Static und Dynamic Google Maps, um den Standort des jeweiligen
          Autohauses auf einer Karte darzustellen.
        </p>
      );
    }
  };

  renderEmpty = (title, anchor) => {
    const { background } = this.props;
    return (
      <ContentText background={background} id={anchor}>
        <h5 className={cn(styles.cookietable_title, styles['cookietable_title--unused'])}>
          {`${getTitle(title)} cookies`}
        </h5>
        <br />
        <p className={styles.cookietable_unused}>
          Aktuell verwendet die Mazda Händlerwebsite ({window.dealer.domain}) keine Cookies dieser
          Art.
        </p>
      </ContentText>
    );
  };

  getDescription = (item) => {
    if (!item.renderAsHtml) return item.description;

    return <span dangerouslySetInnerHTML={{ __html: item.description }} />;
  };

  render() {
    const { type, background, anchor, children } = this.props;
    const information = data[type];

    if (
      information.first_party.length === 0 &&
      information.first_party_additional.length === 0 &&
      information.third_party.length === 0 &&
      !this.additionalInformation()
    ) {
      return this.renderEmpty(type, anchor);
    }

    return (
      <ContentText background={background} id={anchor}>
        <h4 className={styles.cookietable_title}>{`${getTitle(type)} cookies ${
          this.props.type === 'notwendig' || this.props.type === 'komfort'
            ? 'und ähnlche technologien'
            : 'und Beschreibung zum Lieferanten'
        }`}</h4>
        <br />
        {this.additionalInformation()}
        {children}
        {information.first_party.length > 0 && (
          <Fragment>
            <strong className={styles.cookietable_source}>First Party-Cookies*</strong>

            <Table
              styles={styles}
              fixedColumGrey={background === getGivenBackground('grey')}
              headlines={['Cookie Name', 'Verwendungszweck', 'Speicherdauer']}
              rows={information.first_party.map((item) => [
                item.name,
                this.getDescription(item),
                item.expiry,
              ])}
            />

            <p className={styles.cookietable_info}>
              Wenn der Inhalt der Tabelle über den sichtbaren Bereich hinaus geht, scrollen Sie
              bitte die Tabelle.
            </p>
          </Fragment>
        )}
        {information.first_party_additional.length > 0 && (
          <Fragment>
            <strong
              className={cn(
                styles.cookietable_source,
                information.first_party.length > 0 && styles['cookietable_source--secondary']
              )}
            >
              Ergänzende First Party-Cookies*
            </strong>

            <Table
              styles={styles}
              fixedColumGrey={background === getGivenBackground('grey')}
              headlines={['Cookie Name', 'Verwendungszweck', 'Speicherdauer']}
              rows={information.first_party_additional.map((item) => [
                item.name,
                item.description,
                item.expiry,
              ])}
            />

            <p className={styles.cookietable_info}>
              Wenn der Inhalt der Tabelle über den sichtbaren Bereich hinaus geht, scrollen Sie
              bitte die Tabelle.
            </p>
          </Fragment>
        )}
        {information.third_party.length > 0 && (
          <Fragment>
            <strong
              className={cn(
                styles.cookietable_source,
                (information.first_party.length > 0 ||
                  information.first_party_additional.length > 0) &&
                  styles['cookietable_source--secondary']
              )}
            >
              Third Party-Cookies**
            </strong>

            <Table
              styles={styles}
              fixedColumGrey={background === getGivenBackground('grey')}
              headlines={['Cookie Name', 'Verwendungszweck', 'Speicherdauer']}
              rows={information.third_party.map((item) => [
                item.name,
                this.getDescription(item),
                item.expiry,
              ])}
            />

            <p className={styles.cookietable_info}>
              Wenn der Inhalt der Tabelle über den sichtbaren Bereich hinaus geht, scrollen Sie
              bitte die Tabelle.
            </p>
          </Fragment>
        )}
      </ContentText>
    );
  }
}

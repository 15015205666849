import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export default class FlyoutTeaser extends React.Component {
  static propTypes = {
    size: PropTypes.oneOf(['small', 'large']),
    children: PropTypes.node,
    forceMobileMenu: PropTypes.bool,
  };

  render() {
    const { children, size, forceMobileMenu } = this.props;
    const dfmm = { 'data-force-mobile-menu': forceMobileMenu };

    const teaserClass = cx('flyout-teaser', {
      [`flyout-teaser--${size}`]: size,
    });

    return (
      <div className={teaserClass} {...dfmm}>
        {children}
      </div>
    );
  }
}

// @flow
import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { loadAsync } from 'Utils/index';
import DealerModel from 'Stores/DealerModel';
import FootnoteModel from 'Stores/FootnoteModel';
import Footnotes from 'Plugins/Footnotes';

type Props = {
  dealer: DealerModel,
  match: Object,
};

declare var FMM_SNIPPET_BASE_URL: string;

class FMM extends React.Component<Props> {
  constructor() {
    super();
    this.state = {
      footnotes: [],
    };
  }

  componentDidMount() {
    document.body.style.overflow = 'initial';

    // we don't reload the script if the remountSnippet is available
    if (window.remountSnippet) return window.remountSnippet();

    loadAsync(`${process.env.FMM_SNIPPET_BASE_URL}main.css`, true);
    loadAsync(`${process.env.FMM_SNIPPET_BASE_URL}main.js`, true, true, true);

    let pageId = 0;
    const pathname = window.location.pathname;
    if (pathname.includes('neuwagen')) pageId = 41;
    if (pathname.includes('haendlerzulassung')) pageId = 86;
    if (pathname.includes('gebrauchtwagen')) pageId = 88;

    if (pageId !== 0) {
      FootnoteModel.getPageFootnotes(pageId).then((footnotes) => {
        this.setState({ footnotes });
      });
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = '';
  }

  render() {
    const { dealer } = this.props;

    return (
      <Fragment>
        <div id="fmm-snippet" data-dealerid={dealer.demodernDealerId} />
        <div id="fmm-footnotes"></div>

        <Footnotes footnotes={this.state.footnotes} />
      </Fragment>
    );
  }
}

export default withRouter(FMM);

// @flow
import BaseModel from 'Stores/BaseModel';

export default class ContactModel extends BaseModel {
  phone: ?number = null;
  email: ?string = null;
  street: string = '';
  houseNumber: string = '';
  postalCode: string = '';
  city: ?string = null;
  lat: ?number = null;
  lng: ?number = null;

  constructor(data: Object = {}) {
    super();
    this.hydrate(data);
  }
}

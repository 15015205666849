// @flow
import React, { Fragment } from 'react';
import DealerModel from 'Stores/DealerModel';
import LocationCard from '../LocationCard';
import classNames from 'classnames/bind';
import Carousel from 'Components/Carousel';
import Icon from 'Components/Icon';
import Button from 'Components/Button';
import styles from './styles.scss';
import { hasCookie, getCookie } from './../../../../src/utils/index';
import privacyModel from 'Stores/PrivacyModel';
import { COOKIE_KOMFORT } from 'Plugins/CookieSettings';
import { useDebounce } from 'Utils/useDebounce';

const cx = classNames.bind(styles);

declare var PUBLIC_BASE_URL: string;

type Props = {
  dealer: DealerModel,
  open: boolean,
  init: boolean,
  toggle?: (number) => void,
  showMapClicked: boolean,
  setShowMapClicked: () => void,
};

type State = {
  branches: Array<Object>,
  currentActive: boolean,
  selectedItem: number,
  currentBranch: Object,
  cardsInView: number,
  openedHoursAccordions: Array<String>,
};

export default class LocationSwitch extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    // Move current branch to first position
    const sortedBranches = props.dealer.branches.sort(function (a, b) {
      if (a.mazdaId === props.dealer.id) {
        return -1;
      }
      return 1;
    });

    this.carouselRef = React.createRef();

    this.state = {
      branches: sortedBranches,
      currentActive: true,
      selectedItem: 0,
      currentBranch: sortedBranches[0],
      cardsInView: 1,
      openedHoursAccordions: [],
    };

    this.getIsComfortCookieSet = this.showMap.bind(this);
    this.setKomfortChange = this.setKomfortChange.bind(this);
  }

  componentDidMount() {
    this.setDimensions();
    window.addEventListener('resize', useDebounce({ func: this.setDimensions }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.forceOneCard !== this.props.forceOneCard) this.setDimensions();
  }

  onChange = (position: number) => {
    const { branches } = this.state;

    this.setState({ selectedItem: position });
    this.setState({ currentBranch: branches[position] });

    if (position === 0) {
      this.setState({ currentActive: true });
    } else {
      this.setState({ currentActive: false });
    }
  };

  next = () => {
    this.carouselRef.current.next();
  };

  prev = () => {
    this.carouselRef.current.prev();
  };

  onDotClick = (i: number) => {
    return () => {
      this.onChange(i);
    };
  };

  setKomfortChange() {
    privacyModel.updateCookie(COOKIE_KOMFORT, true);
    this.props.setShowMapClicked();
  }

  showMap() {
    const hascookie = hasCookie('privacySettings')
      ? JSON.parse(getCookie('privacySettings'))
      : false;

    return (
      (hascookie && hascookie.cookieTypes && hascookie.cookieTypes.komfort) ||
      this.props.showMapClicked
    );
  }

  get mapPlaceholder() {
    if (this.showMap()) {
      return;
    }

    return (
      <div className={styles.mapPlaceholderWrapper}>
        {' '}
        <div className={styles.mapPlaceholder}>
          <div className={styles.placeholderInfo}>
            <div>
              <span className={styles.placeHolderIcon}>
                <Icon name="address" />
              </span>
              <span className={styles.placeHolderHeader}>Autohaus-Standort</span>
            </div>
            <div className={styles.placeHolderText}>
              Gerne möchten wir Ihnen hier den Google Maps Standort unseres Autohauses anzeigen –
              akzeptieren Sie dazu den Button "Karte anzeigen". Sie können diese Einstellungen
              jederzeit <a href="/datenschutz-praeferenzcenter/">hier</a> anpassen und ihre
              Einwilligung widerrufen. Weiteres finden Sie in unserer{' '}
              <a href="/datenschutz/">Datenschutzerklärung.</a>
            </div>
            <div className={styles.placeHolderButton}>
              {' '}
              <Button
                label="Karte Anzeigen"
                type="secondary"
                onClick={this.setKomfortChange}
                light={true}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  get mapPlaceHolderImage() {
    if (this.showMap()) {
      return;
    }

    return (
      <div className={styles.placeholderImageWrapper}>
        {' '}
        <img src={`${PUBLIC_BASE_URL}/img/map-placeholder.png`} alt="Map Placeholder" />
        <div />
      </div>
    );
  }

  setDimensions = () => {
    const currentWidth = window.innerWidth;
    const { forceOneCard } = this.props;

    if (forceOneCard) {
      this.setState({ cardsInView: 1 });
    } else {
      if (currentWidth < 1000) this.setState({ cardsInView: 1 });
      if (currentWidth > 1000 && currentWidth < 1500) this.setState({ cardsInView: 2 });
      if (currentWidth > 1500 && currentWidth < 2000) this.setState({ cardsInView: 3 });
      if (currentWidth > 2000) this.setState({ cardsInView: 4 });
    }
  };

  resetOpenedHoursAccordions = () => {
    this.setState({
      openedHoursAccordions: [],
    });
  };

  outerControlToggle = (accordionName) => {
    const { openedHoursAccordions } = this.state;

    if (openedHoursAccordions.includes(accordionName)) {
      this.setState({
        openedHoursAccordions: [...openedHoursAccordions.filter((item) => item !== accordionName)],
      });
    } else {
      const newOpenedHoursAccordions = [...openedHoursAccordions].filter(
        (item) => !item.includes(accordionName.substr(0, 5))
      );

      this.setState({
        openedHoursAccordions: [...newOpenedHoursAccordions, accordionName],
      });
    }
  };

  get renderLocationCards() {
    const { dealer, setSharedState } = this.props;
    const { branches, cardsInView, openedHoursAccordions } = this.state;
    let cardIndex = 0;

    const views = [];
    let cards = branches.map((branch, i) => (
      <LocationCard
        e2e={`location_${i}`}
        key={i}
        image={dealer.settings.aboutus.image}
        dealer={dealer}
        branch={branch}
        isCurrentBranch={branch.mazdaId === dealer.id}
        outerControlToggle={this.outerControlToggle}
        openedHoursAccordions={openedHoursAccordions}
        setSharedState={setSharedState}
      />
    ));

    while (cardsInView && cards.length) {
      views.push(
        <Fragment key={cardIndex++}>
          <div className={styles.locationCardContainer}>{cards.slice(0, cardsInView)}</div>
        </Fragment>
      );
      cards = cards.slice(cardsInView, cards.length);
    }

    return views;
  }

  render() {
    const { open } = this.props;

    return (
      <div className={cx('header__location-switch', open && 'header__location-switch--open')}>
        <div
          className={styles.closeIcon}
          onClick={this.props.toggle}
          data-e2e="closeourlocations"
        />
        <div className={styles.imageWrapper}>
          <img src={`${PUBLIC_BASE_URL}/img/map-placeholder.png`} alt="Map Placeholder" />
        </div>
        <Carousel
          showArrows
          highlightedArrows
          showStatus={false}
          transitionTime={700}
          onChange={this.resetOpenedHoursAccordions}
        >
          {this.renderLocationCards}
        </Carousel>
      </div>
    );
  }
}

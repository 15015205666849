// @flow
import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './styles.scss';
import classNames from 'classnames/bind';
import { gaPush } from 'Utils/ga_push';
import { secondLevelTracking } from 'Utils/trackingAttributes';
import { HintUsedCarValuation } from '../LeadNav/HintUsedCarValuation';

const cx = classNames.bind(styles);

type Props = {
  entry: Object,
  parentEntries: Object,
  onClick: (entry: Object) => void,
  location: Object,
};

type State = {
  active: boolean,
  showOverlayHint: boolean,
};

export class FlyoutLink extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      active: this.props.location.pathname === this.props.entry.path,
      showOverlayHint: false,
    };

    this.updateTrackingLabel();
  }

  componentDidMount() {
    document.body.addEventListener('filterchange', (event) => {
      const map = {
        new: '/beratung-und-kauf/fahrzeugboerse/neuwagen/',
        used: '/beratung-und-kauf/fahrzeugboerse/gebrauchtwagen/',
        registered: '/beratung-und-kauf/fahrzeugboerse/haendlerzulassung/',
        rental: '/beratung-und-kauf/fahrzeugboerse/haendlerzulassung/',
        demo: '/beratung-und-kauf/fahrzeugboerse/haendlerzulassung/',
        dealerapproved: '/beratung-und-kauf/fahrzeugboerse/haendlerzulassung/',
      };
      this.setState({ active: map[event.detail.condition] === this.props.entry.path });
    });
  }

  updateTrackingLabel = () => {
    const { entry, parentEntries } = this.props;

    this.trackingLabel = [...parentEntries, entry]
      .reduce((acc, cur) => `${acc} | ${cur.label}`, '')
      .slice(' | '.length);
  };

  componentDidUpdate(prevProps) {
    this.updateTrackingLabel();

    // we run into an endless loop if we don't deny this cause this method is also invoked after state update ofc
    if (prevProps.location.pathname === this.props.location.pathname) {
      return;
    }

    // if the path is equal we change the state to active
    if (this.props.location.pathname === this.props.entry.path) {
      this.setState({ active: true });
    } else if (this.state.active) {
      // if it's active (and not equal since we landed here obviously) change it to inactive
      this.setState({ active: false });
    }
  }

  onClick = (e: SyntheticEvent<HTMLAnchorElement>) => {
    const { entry, onClick } = this.props;

    e.preventDefault();

    if (entry.label === 'Konfigurator') {
      gaPush({
        event: 'gaEvent',
        category: 'header',
        action: 'nav',
        label: 'secondaryheader:Konfigurator clicked',
      });
    }

    onClick(entry);
  };

  openOverlayHint = () => {
    this.setState({ showOverlayHint: true });
  };

  closeOverlayHint = () => {
    this.setState({ showOverlayHint: false });
  };

  render() {
    const { entry } = this.props;
    const { showOverlayHint } = this.state;

    const subnavItemClassName = cx('flyout-subnav__item', {
      'flyout-subnav__item--active': this.state.active,
    });

    if (entry.path.indexOf('my-mazda') > -1) {
      return (
        <li className={subnavItemClassName}>
          <a
            href="https://www.mazda.de/my-mazda/"
            target="_blank"
            rel="noopener noreferrer"
            {...secondLevelTracking(this.trackingLabel)}
          >
            {entry.label}
          </a>
        </li>
      );
    }

    if (entry.dokType === 'shortcut') {
      return (
        <li className={subnavItemClassName}>
          <a
            href={entry.link ? entry.link.href : ''}
            {...secondLevelTracking(this.trackingLabel)}
            target={entry.target}
          >
            {entry.label}
          </a>
        </li>
      );
    }

    if (entry.dokType === 'external_link') {
      return (
        <li className={subnavItemClassName}>
          <a href={entry.url} {...secondLevelTracking(this.trackingLabel)} target={entry.target}>
            {entry.label}
          </a>
        </li>
      );
    }

    if (entry.path.includes('gebrauchtwagenbewertung')) {
      return (
        <Fragment>
          <li className={subnavItemClassName}>
            <button className={styles.overlayButton} onClick={() => this.openOverlayHint()}>
              Gebrauchtwagenbewertung
            </button>
          </li>
          {showOverlayHint && (
            <HintUsedCarValuation
              isOpen={showOverlayHint}
              onClose={() => this.closeOverlayHint()}
              leadtracking={secondLevelTracking(this.trackingLabel)}
              flyout={() => {}}
            />
          )}
        </Fragment>
      );
    }

    return (
      <li className={subnavItemClassName}>
        <a onClick={this.onClick} href={entry.path} {...secondLevelTracking(this.trackingLabel)}>
          {entry.label}
        </a>
      </li>
    );
  }
}

export default withRouter(FlyoutLink);

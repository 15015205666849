import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import Icon from 'Components/Icon';
import styles from './styles.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export default class Modal extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    children: PropTypes.node,
    className: PropTypes.string,
    dialog: PropTypes.bool,
    gallery: PropTypes.bool,
  };

  onClose = () => {
    this.props.onClose();
  };

  onTransitionEnd = () => {
    const { onOpened } = this.props;
    if (onOpened) {
      onOpened();
    }
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      setTimeout(() => {
        const { className } = this.props;
        const modal = document.querySelector(`.${className}`);
        if (modal) {
          modal.addEventListener('transitionend', this.onTransitionEnd);
        }
      });
    }
  }

  componentWillUnmount() {
    const { className, isOpen } = this.props;
    if (isOpen) {
      const modal = document.querySelector(`.${className}`);
      if (modal) {
        modal.removeEventListener('transitionend', this.onTransitionEnd);
      }
    }
  }

  render() {
    const { isOpen, onClose, children, className, dialog, gallery } = this.props;

    const contentClass = cx('content', {
      dialog,
      gallery,
    });

    return (
      <ReactModal
        isOpen={isOpen}
        overlayClassName={styles.overlay}
        ariaHideApp={false}
        className={classNames(styles.outer, className)}
        onRequestClose={this.onClose}
        shouldCloseOnOverlayClick={true}
        shouldReturnFocusAfterClose={false}
      >
        <div className={contentClass}>
          {children}
          {!dialog && (
            <button className={styles.closebtn} onClick={onClose}>
              <Icon name="close" className={styles.icon} inline={false} />
            </button>
          )}
        </div>
      </ReactModal>
    );
  }
}

// @flow
import axios from 'axios';
import BaseModel from 'Stores/BaseModel';
import AboutUsModel from 'Stores/AboutUsModel';
import CareerModel from 'Stores/CareerModel';
import { isInvalid, validateLength, validateEmail, isValidMail } from 'Utils/Validators';

type SocialMedia = {
  instagram: string,
  facebook: string,
  youtube: string,
  twitter: string,
  dealerlink: string,
  dealerlinkTitle: string,
};

type Startpage = {
  title: string,
  text: string,
  headerLayout: string,
};

type Notifications = { email: Array<string> };

type Privacy = {
  name: string,
  street: string,
  streetNumber: string,
  city: string,
  postalCode: string,
  email: string,
  text: string,
};

type ContentPrivacy = {
  offersPrivate: boolean,
  offersBusiness: boolean,
  teasers: boolean,
};

type Imprint = {
  privacyText: string,
  text: string,
};

export default class DealerSettingsModel extends BaseModel {
  aboutus: AboutUsModel;

  career: CareerModel;

  startpage: Startpage = {
    title: '',
    text: '',
    headerLayout: '',
  };

  privacy: Privacy = {
    name: '',
    street: '',
    streetNumber: '',
    city: '',
    postalCode: '',
    email: '',
    text: '',
  };

  contentPrivacy: ContentPrivacy = {
    offersPrivate: true,
    offersBusiness: true,
    teasers: true,
    onlineTermin: false,
  };

  notifications: Notifications = {
    email: [],
  };

  imprint: Imprint = {
    privacyText: '',
    text: '',
  };

  // Whether the dealer wants the page "KFZ Versicherung" visible or not
  showCarInsurance: boolean = true;

  showBrochure: boolean = true;
  showUsedcarValuation: boolean = true;

  // TESTDATA
  isOsbUser: boolean = true;
  isSalesPartner: boolean = false;
  isServicePartner: boolean = false;
  salesPartnerOnly: boolean = false;
  // Whether the dealer is "just" a service partner or a full sales partner
  servicePartnerOnly: boolean = false;
  customTopCars: Object = {};

  socialMedia: SocialMedia = {
    facebook: '',
    instagram: '',
    youtube: '',
    twitter: '',
    dealerlink: '',
    dealerlinkTitle: '',
  };

  onboardingComplete: boolean = false;
  offersOnlineConsultation: boolean = false;
  showAccessoryPrice: boolean = false;
  // in lead forms a different dealer id might be used
  leadsMimicDealerId: number | null;

  constructor(data: Object = {}) {
    super();

    this.aboutus = new AboutUsModel(data.aboutus || {});
    this.career = new CareerModel(data.career || this.career || {});
    this.socialMedia = data.socialMedia || this.socialMedia;
    this.startpage = data.startpage || this.startpage;
    this.privacy = data.privacy || this.privacy;
    this.contentPrivacy = data.contentPrivacy || this.contentPrivacy;
    this.notifications = data.notifications || this.notifications;
    this.imprint = data.imprint || this.imprint;
    this.showBrochure = data.hasOwnProperty('showBrochure') ? data.showBrochure : this.showBrochure;
    this.showUsedcarValuation = data.hasOwnProperty('showUsedcarValuation')
      ? data.showUsedcarValuation
      : this.showUsedcarValuation;
    this.showCarInsurance = data.hasOwnProperty('showCarInsurance')
      ? data.showCarInsurance
      : this.showCarInsurance;
    this.showCarConfigurator = data.showCarConfigurator;
    this.customerAllianceWidget = {
      isActive: data.customerAllianceWidget && data.customerAllianceWidget.isActive,
      url: (data.customerAllianceWidget && data.customerAllianceWidget.url) || null,
      hasId: data.customerAllianceWidget && data.customerAllianceWidget.hasId,
    };
    this.whatsappWidget = {
      isActive: data.whatsappWidget && data.whatsappWidget.isActive,
      whatsappUrl: data.whatsappWidget && data.whatsappWidget.whatsappUrl,
    };
    this.isOsbUser = data.isOsbUser;
    this.isSalesPartner = data.isSalesPartner;
    this.isServicePartner = data.isServicePartner;
    this.salesPartnerOnly = data.isServicePartner === false && data.isSalesPartner === true;
    this.servicePartnerOnly = data.isServicePartner === true && data.isSalesPartner === false;
    this.onboardingComplete = data.onboardingComplete;
    this.offersOnlineConsultation = data.offersOnlineConsultation;
    this.showAccessoryPrice = data.showAccessoryPrice;
    this.customTopCars = data.customTopCars;
    this.leadsMimicDealerId = data.leadsMimicDealerId;
    this.showLocationLink = data.showLocationLink;
  }

  getSocialMediaLinks = () => {
    const links = [];

    Object.keys(this.socialMedia).forEach((key) => {
      if (
        this.socialMedia[key] &&
        this.socialMedia[key].length > 0 &&
        key !== 'dealerlink' &&
        key !== 'dealerlinkTitle'
      ) {
        links.push({
          key: key,
          href: this.socialMedia[key],
        });
      }
    });

    return links;
  };

  valid = () => {
    this.errors = {};

    const emailErrors = {};

    this.notifications.email.forEach((item, index) => {
      validateLength(emailErrors, 'email', item, 1, 50);
      validateEmail(emailErrors, `email--${index}`, item);
      if (!isValidMail(item)) {
        emailErrors['email'] = 'Bitte geben Sie eine gültige E-Mail-Adresse ein';
      }
    });

    if (Object.keys(emailErrors).length > 0) {
      this.errors['notifications'] = emailErrors;
    }

    return !isInvalid(this.errors);
  };

  save = (settings: Object) => {
    return axios({
      method: 'patch',
      url: '/api/v1/dealers/',
      data: {
        settings: {
          ...settings,
        },
      },
    }).then((res) => {
      Object.assign(this, settings);
      return this;
    });
  };
}

// @flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import privacyModel from 'Stores/PrivacyModel';
import { gaPush } from 'Utils/ga_push';

type Props = {
  location: {
    pathname: string,
  },
  children: Node,
};

type State = {
  initalView: boolean,
};

const trackPage = (page: string) =>
  gaPush({
    dealerName: window.dealer.name,
    dealerNumber: window.dealer.dealerId,
    pageType: page,
  });

class Analytics extends React.Component<Props, State> {
  state = {
    initalView: true,
  };

  componentDidUpdate(prevProps: Props) {
    const currentPage = prevProps.location.pathname;
    const nextPage = this.props.location.pathname;

    if (!privacyModel.trackingEnabled()) {
      return;
    }

    if (currentPage !== nextPage || this.state.initalView) {
      trackPage(nextPage);
      this.setState({ initalView: false });
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(Analytics);

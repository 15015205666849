exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1p_LppO-qCRAGUuZPUuLK1{min-height:100%}._1p_LppO-qCRAGUuZPUuLK1 main{min-height:100vh}._1p_LppO-qCRAGUuZPUuLK1._20SuX4b2HK8bh3O93wFD-j main{min-height:0}\n", ""]);

// exports
exports.locals = {
	"layout": "_1p_LppO-qCRAGUuZPUuLK1",
	"initalized": "_20SuX4b2HK8bh3O93wFD-j"
};
import { lazy } from 'react';
import { TopCars } from '../../containers/FMM';
import { LinkSection } from '../LinkSection';
import LinkSectionLink from '../LinkSection/link';
const Cards = lazy(() => import(/* webpackChunkName: 'Cards' */ '../Cards'));
const Consumption = lazy(() =>
  import(/* webpackChunkName: 'Consumption' */ 'Components/Consumption')
);
const DealerPrivacy = lazy(() =>
  import(/* webpackChunkName: 'DealerPrivacy' */ '../../plugins/DealerPrivacy')
);
const DealerImprint = lazy(() =>
  import(/* webpackChunkName: 'DealerImprint' */ '../../plugins/DealerImprint')
);
const Imprint = lazy(() => import(/* webpackChunkName: 'Imprint' */ '../../plugins/Imprint'));
const HeroHeader = lazy(() => import(/* webpackChunkName: 'HeroHeader' */ '../HeroHeader'));
const IntroText = lazy(() => import(/* webpackChunkName: 'IntroText' */ '../IntroText'));
const SingleButton = lazy(() => import(/* webpackChunkName: 'SingleButton' */ '../SingleButton'));
const Teaser = lazy(() => import(/* webpackChunkName: 'Teaser' */ '../Teaser'));
const ContentText = lazy(() => import(/* webpackChunkName: 'ContentText' */ '../ContentText'));
const OfferList = lazy(() => import(/* webpackChunkName: 'OfferList' */ '../../plugins/OfferList'));
const Footnotes = lazy(() => import(/* webpackChunkName: 'Footnotes' */ '../../plugins/Footnotes'));
const InspectionChessboard = lazy(() =>
  import(/* webpackChunkName: 'InspectionChessboard' */ '../../plugins/InspectionChessboard')
);
const InspectionProcess = lazy(() =>
  import(/* webpackChunkName: 'InspectionProcess' */ '../../plugins/InspectionProcess')
);
const CookieSettings = lazy(() =>
  import(/* webpackChunkName: 'CookieSettings' */ '../../plugins/CookieSettings')
);
const Highlights = lazy(() =>
  import(/* webpackChunkName: 'Highlights' */ '../../plugins/Highlights')
);
const DealerToolboxDetails = lazy(() =>
  import(/* webpackChunkName: 'DealerToolboxDetails' */ '../../plugins/DealerToolboxDetails')
);
const IframeSource = lazy(() => import(/* webpackChunkName: 'IframeSource' */ '../IframeSource'));
const TeamList = lazy(() => import(/* webpackChunkName: 'TeamList' */ '../../plugins/TeamList'));
const GradientBox = lazy(() => import(/* webpackChunkName: 'GradientBox' */ '../GradientBox'));
const GridContentBox = lazy(() =>
  import(/* webpackChunkName: 'GridContentBox' */ '../GridContentBox')
);
const TopNewsEvents = lazy(() =>
  import(/* webpackChunkName: 'TopNewsEvents' */ '../../plugins/TopNewsEvents')
);
const NewsDetails = lazy(() =>
  import(/* webpackChunkName: 'NewsDetails' */ '../../plugins/NewsDetails')
);
const NewsList = lazy(() => import(/* webpackChunkName: 'NewsList' */ '../../plugins/NewsList'));
const CareerDetails = lazy(() =>
  import(/* webpackChunkName: 'CareerDetails' */ '../../plugins/CareerDetails')
);
const CareerJobList = lazy(() =>
  import(/* webpackChunkName: 'CareerJobList' */ '../../plugins/CareerJobList')
);
const Gallery = lazy(() => import(/* webpackChunkName: 'Gallery' */ '../Gallery'));
const ChessboardRenderer = lazy(() =>
  import(/* webpackChunkName: 'ChessboardRenderer' */ '../Chessboard/renderer')
);
const ChessboardItem = lazy(() =>
  import(/* webpackChunkName: 'ChessboardItem' */ '../Chessboard/item')
);
const Video = lazy(() => import(/* webpackChunkName: 'Video' */ '../Video'));
const ServiceDetails = lazy(() =>
  import(/* webpackChunkName: 'ServiceDetails' */ '../../plugins/ServiceDetails')
);
const ServiceCategoryList = lazy(() =>
  import(/* webpackChunkName: 'ServiceCategoryList' */ '../../plugins/ServiceCategoryList')
);
const AccessoriesList = lazy(() =>
  import(/* webpackChunkName: 'AccessoriesList' */ '../../plugins/AccessoriesList')
);
const AccessoriesDetails = lazy(() =>
  import(/* webpackChunkName: 'AccessoriesDetails' */ '../../plugins/AccessoriesDetails')
);
const AboutUs = lazy(() => import(/* webpackChunkName: 'AboutUs' */ '../../plugins/AboutUs'));
const LinkBox = lazy(() => import(/* webpackChunkName: 'LinkBox' */ 'Components/LinkBox'));

export const GENERIC_COMPONENT_MAP = {
  mask_chessboard: ChessboardRenderer,
  mask_chessboard_item: ChessboardItem,
  mask_consumption_info: Consumption,
  mask_hero_header_compact: HeroHeader,
  mask_hero_header_small: HeroHeader,
  mask_hero_header_big: HeroHeader,
  mask_hero_header_full: HeroHeader,
  mask_intro_text: IntroText,
  mask_gradient_box: GradientBox,
  mask_contentbox_2columns: GridContentBox,
  mask_contentbox_3columns: GridContentBox,
  mask_link_section: LinkSection,
  mask_link_section_item: LinkSectionLink,
  mask_video: Video,
  mask_json: IframeSource,
  mask_single_button: SingleButton,
  mask_gallery: Gallery,
  mask_teaser_area_stage: Teaser,
  mask_teaser_area_content: Teaser,
  mask_content_text: ContentText,
  mask_content_pagelinks: LinkBox,
  plugin_mazdaapi_offers_business: OfferList,
  plugin_mazdaapi_offers_private: OfferList,
  plugin_mazdaapi_offers_special: OfferList,
  plugin_mazdaapi_offers_special_inline: OfferList,
  plugin_mazdaapi_services_list: ServiceCategoryList,
  plugin_mazdaapi_services_detail: ServiceDetails,
  plugin_mazdaapi_career_job_list: CareerJobList,
  plugin_mazdaapi_career_job_detail: CareerDetails,
  plugin_mazdaapi_newsevents_list: NewsList,
  plugin_mazdaapi_newsevents_detail: NewsDetails,
  plugin_mazdaapi_top_news_events: TopNewsEvents,
  plugin_mazdaapi_team_list: TeamList,
  plugin_mazdaapi_accessories_list: AccessoriesList,
  plugin_mazdaapi_accessories_detail: AccessoriesDetails,
  plugin_mazdaapi_aboutus: AboutUs,
  plugin_footnotes: Footnotes,
  plugin_footnotes_landingpage: Footnotes,
  plugin_mazdaapi_imprint: Imprint,
  plugin_mazdaapi_inspection_chessboard: InspectionChessboard,
  plugin_mazdaapi_inspection_process: InspectionProcess,
  plugin_mazdaapi_cookies: CookieSettings,
  plugin_mazdaapi_dealer_privacy: DealerPrivacy,
  plugin_mazdaapi_dealer_imprint: DealerImprint,
  mask_plugin_landingpage_highlights_business: Highlights,
  mask_plugin_landingpage_highlights_service: Highlights,
  plugin_mazdaapi_elite_service_offers: OfferList,
  plugin_mazdaapi_dealertoolbox_detail: DealerToolboxDetails,
  mask_plugin_entrypage_subsites: Cards,
  mask_plugin_entrypage_showroom: Cards,
  mask_plugin_fmm_topcars: TopCars,
};

export const GENERIC_TYPES_TO_WRAP = {
  mask_link_section_item: 'mask_link_section',
  mask_chessboard_item: 'mask_chessboard',
};

// TODO: THIS IS TERRIBLE AND SHOULD BE DONE IN BACKEND!!!
export const extractSpecialOffers = (content = []) => {
  const landingpageHighlights = content.find(
    content => content.type === 'mask_plugin_landingpage_highlights_business'
  );

  if (!landingpageHighlights) {
    return [];
  }

  return landingpageHighlights.highlightElements.reduce((offerAcc, highlightElement) => {
    const offerLists = highlightElement.content.reduce((acc, { type, offerList }) => {
      // only offers-plugins with offers
      if (type.indexOf('plugin_mazdaapi_offers_') === 0 && offerList && offerList.length > 0) {
        return [...acc, ...offerList];
      }

      return acc;
    }, []);

    return !offerLists.length ? offerAcc : [...offerAcc, ...offerLists];
  }, []);
};

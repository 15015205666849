import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export default class Check extends React.Component {
  inputRef = createRef();

  static propTypes = {
    label: PropTypes.string,
    children: PropTypes.node,
    name: PropTypes.string,
    checked: PropTypes.bool,
    errorMessage: PropTypes.string,
    onChange: PropTypes.func,
    e2e: PropTypes.string,
    maxLength: PropTypes.string,
  };

  onChange = (e) => {
    this.props.onChange(e.target.checked, this.props.name);
  };

  handleClick = () => {
    if (this.inputRef.current) {
      this.inputRef.current.click();
    }
  };

  render() {
    const { label, children, name, checked, errorMessage, e2e, maxLength } = this.props;

    const checkboxClass = cx('checkbox', {
      error: errorMessage,
    });

    const completeLabel = label || children;
    let labelParts = [completeLabel];
    if (completeLabel.indexOf('\\n') !== -1) {
      labelParts = completeLabel.split('\\n');
    }
    return (
      <div className={checkboxClass}>
        <input
          id={`checkbox-${name}`}
          type="checkbox"
          name={name}
          defaultChecked={checked}
          onChange={this.onChange}
          data-e2e={e2e}
          maxLength={maxLength || 255}
        />
        <label htmlFor={`checkbox-${name}`} ref={this.inputRef} />
        <div
          onClick={this.handleClick}
          className={cx(styles.textWrapper, styles.lessLineHeight, styles.hover)}
        >
          {labelParts.map((labelPart) => (
            <div>{labelPart}</div>
          ))}
        </div>
      </div>
    );
  }
}

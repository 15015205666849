exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@keyframes _3Ip591Bw4xFqCpO-beQkiF{from{transform:translate3d(0, 1rem, 0);visibility:visible;opacity:0}to{transform:translate3d(0, 0, 0);opacity:1}}@keyframes _2aiS-tpM83RRdQWgHFQGu1{from{visibility:visible;opacity:0}to{opacity:1}}@keyframes _15EGg5rKfAqHjaTNWbsu5T{0%{filter:blur(3.125rem)}25%{filter:blur(1.25rem)}100%{filter:blur(0)}}._2tvn_yfiTpk_IaTBb6-faO{background-color:#191919;padding-bottom:5rem}._2bPrdJlzbK46YGWoQmrf2d{margin-top:0.5rem;border-top:0.0625rem solid #9a9a9a;padding:1.375rem 0 2rem 0;text-align:center;min-height:10rem}@media screen and (min-width: 768px){._2bPrdJlzbK46YGWoQmrf2d{text-align:left}}._2bPrdJlzbK46YGWoQmrf2d a:hover{color:#fff}.R8k1AWYJ80LargHBKJori{font-family:MazdaTypeBold, sans-serif;font-size:0.75rem;color:#9a9a9a;letter-spacing:0.115rem;line-height:0.9375rem;text-decoration:none;text-transform:uppercase;display:inline-block;position:relative;height:1.25rem;line-height:1.25rem}.R8k1AWYJ80LargHBKJori:not(:last-child){padding-right:0.75rem;margin-right:0.75rem}.R8k1AWYJ80LargHBKJori:not(:last-child)::after{position:absolute;content:'';right:0;top:0.25rem;height:0.75rem;width:0.0625rem;background:#9a9a9a}._1grT2a669vKtYmt0ktOaF2{font-family:MazdaTypeBold, sans-serif;font-size:0.75rem;color:#9a9a9a;letter-spacing:0.115rem;line-height:0.9375rem;text-decoration:none;text-transform:uppercase;color:#6e6e6e}\n", ""]);

// exports
exports.locals = {
	"footer": "_2tvn_yfiTpk_IaTBb6-faO",
	"legal": "_2bPrdJlzbK46YGWoQmrf2d",
	"legalLink": "R8k1AWYJ80LargHBKJori",
	"version": "_1grT2a669vKtYmt0ktOaF2",
	"slideinup": "_3Ip591Bw4xFqCpO-beQkiF",
	"fadein": "_2aiS-tpM83RRdQWgHFQGu1",
	"blur": "_15EGg5rKfAqHjaTNWbsu5T"
};
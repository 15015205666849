// @flow
import React from 'react';
import { parse } from 'qs';
import DefaultPage from 'Containers/DefaultPage';
import HeroHeader from 'Components/HeroHeader';

declare var ENVIRONMENT: string;
declare var PUBLIC_BASE_URL: string;

type Props = {
  title?: ?string,
  details?: ?string,
  error?: Error,
  status?: ?number,
};

type State = {
  title: string,
  details: string,
  status: number,
};

const defaults = {
  '404': {
    title: 'Seite wurde nicht gefunden',
    details:
      'Die von Ihnen gesuchte Seite ist nicht vorhanden. Bitte überprüfen Sie die URL-Eingabe.',
  },
  '401': {
    title: 'Autorisierung erforderlich',
    details: 'Die von Ihnen gewählte Seite erfordert eine Autorisierung.',
  },
  '403': {
    title: 'Zugriff verweigert',
    details: 'Sie verfügen nicht über die erforderliche Berechtigung, diese Seite anzuzeigen.',
  },
  '500': {
    title: 'Ein Fehler ist aufgetreten',
    details:
      'Leider ist ein technischer Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt nochmals.',
  },
  '503': {
    title: 'Geplante Wartungsarbeiten',
    details:
      'Wir verbessern derzeit die Mazda-Website für Sie. Während dieser Zeit funktionieren unsere Online-Dienste nicht immer wie gewohnt. Bitte versuchen Sie es zu einem späteren Zeitpunkt nochmals.',
  },
  '518': {
    title: 'Geplante Wartungsarbeiten',
    details:
      'Wir verbessern derzeit die Mazda-Website für Sie. Während dieser Zeit funktionieren unsere Online-Dienste nicht immer wie gewohnt. Ab dem 02. Mai sind unsere Dienste wieder vollumfänglich für Sie verfügbar.',
  },
};

export default class ErrorPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      title: props.title || this.getDefault('title'),
      details: props.details || this.getDefault('details'),
      status: props.status || this.getStatusFromUrlQuery(),
    };
  }

  getStatusFromUrlQuery() {
    const query = parse(window.location.search.replace('?', ''));
    return query.status || '404';
  }

  getDefault = (property: string) => {
    const { status } = this.props;
    const statusStr = status ? status.toString() : '500';

    return defaults[statusStr][property];
  };

  render() {
    const { title, details, status } = this.state;

    if (ENVIRONMENT === 'DEVELOPMENT') {
      console.error(`App initialization error: ${status}`);
    }

    return (
      <DefaultPage>
        <HeroHeader
          small
          subtitle={title}
          introtext={details}
          image={{
            src: `${PUBLIC_BASE_URL}/img/mazda_hws_404.png`,
            blur: false,
          }}
          backlink={status < 500}
        />
        {this.props.children || null}
      </DefaultPage>
    );
  }
}

// @flow
import React from 'react';
import { loadAsync } from 'Utils/index';
import DealerModel from 'Stores/DealerModel';

type Props = {
  dealer: DealerModel,
};

export default class Alliance extends React.Component<Props> {
  componentDidMount() {
    const { dealer } = this.props;

    if (
      dealer.settings.customerAllianceWidget &&
      dealer.settings.customerAllianceWidget.isActive &&
      dealer.settings.customerAllianceWidget.url
    ) {
      loadAsync(dealer.settings.customerAllianceWidget.url);
    }
  }

  render() {
    return null;
  }
}

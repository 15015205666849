import React, { Suspense } from 'react';
import { Redirect } from 'react-router-dom';
import ErrorPage from '../../containers/ErrorPage';
import { GENERIC_COMPONENT_MAP } from './constants';
import { getBackground, getGivenBackground, startsReversed } from 'Utils/getColor';

/*
 * MAZDAHWS-1629: Code was copy & pasted from GenericContainer/index.js.
 * See GenericContainer/index.js for Git-History / Blame!
 * #wasntme ;)
 * */
export default class EkiContentRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: false,
      yOffset: 0,
      scrollAttempt: 0,
      deeplinkTaregtAvailable: false,
      deeplinkTargetSearchTime: 0,
    };
  }

  findDeeplinkTarget(deeplinkid) {
    setTimeout(() => {
      const scrollTarget = document.getElementById(deeplinkid);

      if (scrollTarget) {
        this.setState({ deeplinkTaregtAvailable: true });
      } else {
        if (this.state.deeplinkTargetSearchTime < 3000) {
          this.setState({ deeplinkTargetSearchTime: this.state.deeplinkTargetSearchTime + 100 });
          this.findDeeplinkTarget(deeplinkid);
        }
      }
    }, 100);
  }

  componentDidMount() {
    const deeplinkid =
      this.props.urlParams && this.props.urlParams.deeplinkid
        ? this.props.urlParams.deeplinkid
        : undefined;

    this.findDeeplinkTarget(deeplinkid);
  }

  componentDidUpdate(_, prevState) {
    if (this.state.deeplinkTaregtAvailable && !prevState.deeplinkTaregtAvailable) {
      const deeplinkid =
        this.props.urlParams && this.props.urlParams.deeplinkid
          ? this.props.urlParams.deeplinkid
          : undefined;

      const scrollTarget = document.getElementById(deeplinkid);
      this.setState({
        yOffset: window.pageYOffset,
      });

      if (scrollTarget) {
        this.scroll(scrollTarget.getBoundingClientRect().top);
      }
    }
  }

  scroll(scrollTargetOffset) {
    if (this.props.preventScrollToDeeplikId) {
      return;
    }

    const { scrollAttempt, scrolled } = this.state;

    if (!scrollTargetOffset || scrolled || scrollAttempt > 20) {
      return;
    }

    this.props.scrollToDeeplinkId(true);
    this.setState({
      scrollAttempt: scrollAttempt + 1,
    });

    if (window.pageYOffset > 0) {
      this.setState({
        scrolled: true,
      });

      return;
    }

    setTimeout(() => {
      if (!scrolled) {
        this.scroll(scrollTargetOffset);
      }
    }, 300);
  }

  prepareContent(content, dealerId) {
    const elementsToWrap = ['mask_chessboard_item', 'mask_link_section_item'];

    const result = [];
    let hasForm = null;

    for (let i = 0; i < content.length; i++) {
      if (
        (dealerId === '11292' || dealerId === '15664') &&
        (content[i].id === 219 || content[i].id === 220 || content[i].id === 222)
      )
        continue;

      if (
        elementsToWrap.indexOf(content[i].type) >= 0 &&
        content[i + 1] &&
        elementsToWrap.indexOf(content[i + 1].type) >= 0 &&
        elementsToWrap.indexOf(content[i].type) === elementsToWrap.indexOf(content[i + 1].type)
      ) {
        const currentType = content[i].type;
        const items = [content[i]];
        let j = i;
        while (content[j + 1] && currentType === content[j + 1].type) {
          items.push(content[j + 1]);
          j++;
        }
        const elementName = content[i + 1].type.split('_item')[0];
        result.push({
          id: `${elementName}_${i}`,
          type: elementName,
          items,
        });
        i = j;
      } else {
        if (content[i].type === 'mask_form') {
          hasForm = content[i].formType;
        }

        result.push(content[i]);
      }
    }

    return [result, hasForm];
  }

  renderContentElement(contentElm, index, hasForm, lastBackground) {
    const {
      mainStore,
      pageId,
      dealer,
      history,
      onOpenContact,
      entry,
      sharedState,
      setSharedState,
      hasForm: hasFormAbove,
    } = this.props;
    const Component = GENERIC_COMPONENT_MAP[contentElm.type];
    const isNoLP = !entry.dokType || !entry.dokType.startsWith('landingpage');

    // TODO: only introducing alternating background color logic for lps by now.
    //  actually the logic applies to all pages, but
    // we only introduce it to landingpages by now, since we should remove all background fields
    // from typo and api responses when we introduce the feature globally.
    // this refactoring should be paid by mazda and is out of scope in our landingpage context.
    const background = (() => {
      if (isNoLP) {
        return getGivenBackground(contentElm.background);
      }

      if (!lastBackground) {
        return getBackground(index);
      }

      return lastBackground === getGivenBackground('white')
        ? getGivenBackground('grey')
        : getGivenBackground('white');
    })();

    const offerList = contentElm.offerList
      ? contentElm.offerList.filter(
          (offer) =>
            !!offer &&
            !offer.hidden &&
            (!!offer.cashPrice ||
              !!offer.leasingPrice ||
              !!offer.financePrice ||
              offer.price !== 'undefined')
        )
      : undefined;

    if (Component) {
      return [
        <Suspense fallback={<div></div>}>
          <Component
            key={`${contentElm.id}-${index}`}
            hasForm={isNoLP ? undefined : hasForm || hasFormAbove}
            deeplinkId={contentElm.deeplinkId || `content-element-${index}`}
            consumptionText={contentElm.consumptionText}
            {...{
              pageId,
              dealer,
              history,
              mainStore,
              onOpenContact,
              entry,
              sharedState,
              setSharedState,
            }}
            {...contentElm}
            {...(!!offerList ? { offerList } : {})}
            background={background}
          />
        </Suspense>,
        !contentElm.offerList || !contentElm.offerList.length
          ? background
          : getBackground(offerList.length - 1, startsReversed(background)),
      ];
    }

    return [<div key={contentElm.id}>Component {contentElm.type} not found!</div>, null];
  }

  getErrorPage() {
    return (
      <ErrorPage
        key="error"
        title="Kein Inhalt verfügbar"
        details="Für diese Seite wurde kein Inhalt hinterlegt."
      />
    );
  }

  render() {
    const { content: propsContent, dealer, status } = this.props;

    if (!propsContent) {
      return this.getErrorPage();
    }

    const [content, hasForm] = this.prepareContent(
      propsContent.filter((contentElement) => !contentElement.hidden),
      dealer.id
    );

    if (!content.length) {
      if (status === 410) {
        return <Redirect to="/" />;
      }
      return this.getErrorPage();
    }

    let lastBackground = null;
    const components = [];
    for (let i = 0; i < content.length; i++) {
      const [component, lastBg] = this.renderContentElement(content[i], i, hasForm, lastBackground);
      components.push(component);
      lastBackground = lastBg;
    }

    return <React.Fragment>{components}</React.Fragment>;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import CallToAction from 'Components/CallToAction';
import ConsumptionData from 'Components/ConsumptionData';
import { Container, Row, Col } from 'Components/Grid';
import classNames from 'classnames/bind';
import styles from './styles.scss';
import { getGivenBackground } from 'Utils/getColor';

const cx = classNames.bind(styles);

const spaceType = [undefined, '', 'extra-small', 'small', 'medium'];

export default class IntroText extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    headerLayout: PropTypes.string,
    cta1: PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      href: PropTypes.string,
      additional: PropTypes.string,
      track: PropTypes.bool,
    }),
    cta2: PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
      href: PropTypes.string,
      additional: PropTypes.string,
      track: PropTypes.bool,
    }),
    background: PropTypes.string,
    onOpenContact: PropTypes.func,
    deeplinkId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    consumptionText: PropTypes.string,
    spaceBeforeClass: PropTypes.oneOf(spaceType),
    spaceAfterClass: PropTypes.oneOf(spaceType),
  };

  static defaultProps = {
    background: getGivenBackground('white'),
  };

  renderCta = cta => {
    const { onOpenContact } = this.props;

    return (
      <div className={styles.cta}>
        <CallToAction {...cta} onOpenContact={onOpenContact} />
      </div>
    );
  };

  renderTitle = (title, headerLayout) => {
    if (headerLayout === 'h1') {
      return <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />;
    }

    return <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />;
  };

  render() {
    const {
      title,
      text,
      cta1,
      cta2,
      background,
      headerLayout,
      deeplinkId,
      consumptionText,
      spaceBeforeClass,
      spaceAfterClass,
    } = this.props;

    const backgroundClass = cx(background);

    const rootClass = cx(styles.root, {
      [styles.spaceBeforeExtraSmall]: spaceBeforeClass === 'extra-small',
      [styles.spaceBeforeSmall]: spaceBeforeClass === 'small',
      [styles.spaceBeforeMedium]: !spaceBeforeClass || spaceBeforeClass === 'medium',

      [styles.spaceAfterExtraSmall]: spaceAfterClass === 'extra-small',
      [styles.spaceAfterSmall]: spaceAfterClass === 'small',
      [styles.spaceAfterMedium]: !spaceAfterClass || spaceAfterClass === 'medium',
    });

    return (
      <div className={backgroundClass} id={deeplinkId}>
        <Container>
          <div className={rootClass}>
            <Row align="center">
              <Col cols={12} m={10} l={8}>
                {title && this.renderTitle(title, headerLayout)}
                {text && <p className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />}
                {cta1 && cta1.label && this.renderCta(cta1)}
                {cta2 && cta2.label && this.renderCta(cta2)}
              </Col>
            </Row>
          </div>
        </Container>
        {consumptionText && <ConsumptionData consumptionText={[consumptionText]} />}
      </div>
    );
  }
}

// @flow
import React from 'react';
import Icon from 'Components/Icon';
import styles from './styles.scss';
import classNames from 'classnames/bind';
import { firstLevelTracking } from 'Utils/trackingAttributes';

const cx = classNames.bind(styles);

type Props = {
  entry: Object,
  onClick: (entry: Object) => void,
  active: boolean,
  open: boolean,
  forceMobileMenu: boolean,
};

type State = {
  open: boolean,
};

export default class MainEntry extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  onClick = () => {
    this.props.onClick(this.props.entry);
  };

  render() {
    const { entry, open, active, forceMobileMenu } = this.props;
    const dfmm = { 'data-force-mobile-menu': forceMobileMenu };

    const navItemClass = cx('main-nav__item', {
      'main-nav__item--open': open,
      'main-nav__item--active': active && !open,
    });

    return (
      <li
        key={entry.to}
        data-name={entry.label}
        className={navItemClass}
        onClick={this.onClick}
        {...dfmm}
      >
        <span {...firstLevelTracking(entry.label)}>{entry.label}</span>
        <Icon name="caret-left" className={styles.icon} inline={false} />
      </li>
    );
  }
}

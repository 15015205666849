// @flow
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import styles from './styles.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

type Props = {
  children: Node,
  infiniteLoop?: boolean,
  selectedItem?: number,
  onChange?: number => void,
  autoPlay?: boolean,
  showIndicators?: boolean,
  showArrows?: boolean,
  showStatus?: boolean,
  stopOnHover?: boolean,
  interval?: number,
  transitionTime?: number,
  draggable?: boolean,
  className?: string,
  updateCurrentIndex?: number => void,
  wholeAreaIsClickable?: boolean,
  highlightedArrows?: boolean,
  postionTeaser?: boolean,
};

type State = {
  currentIndex: number,
};

export default class Carousel extends React.Component<Props, State> {
  static defaultProps = {
    infiniteLoop: true,
    selectedItem: 0,
    autoPlay: false,
    showIndicators: false,
    showArrows: true,
    showStatus: true,
    stopOnHover: true,
    interval: 6000,
    transitionTime: 350,
    draggable: true,
    className: null,
    postionTeaser: false,
  };

  slider: Node;

  constructor(props: Props) {
    super(props);

    this.state = {
      currentIndex: props.selectedItem + 1 || 1,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.updateCurrentIndex && this.state.currentIndex !== prevState.currentIndex) {
      this.props.updateCurrentIndex(this.state.currentIndex);
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps: Props) => {
    if (this.slider && this.props.selectedItem !== nextProps.selectedItem) {
      this.slider.slickGoTo(nextProps.selectedItem);
    }
  };

  onChange = (oldIndex: number, newIndex: number) => {
    this.setState({ currentIndex: newIndex + 1 });
    this.props.onChange && this.props.onChange(newIndex);
  };

  setRef = (slider: Node) => {
    this.slider = slider;
  };

  next = () => {
    this.slider.slickNext();
  };

  prev = () => {
    this.slider.slickPrev();
  };

  handleClick = event => {
    if (event.clientX < window.innerWidth / 2) {
      this.prev();
    } else {
      this.next();
    }
  };

  render() {
    const {
      children,
      infiniteLoop,
      selectedItem,
      autoPlay,
      showIndicators,
      showArrows,
      showStatus,
      stopOnHover,
      interval,
      transitionTime,
      draggable,
      className,
      wholeAreaIsClickable,
      highlightedArrows,
      postionTeaser,
    } = this.props;

    const settings = {
      infinite: infiniteLoop,
      dots: showIndicators,
      arrows: showArrows,
      adaptiveHeight: false,
      autoplay: autoPlay,
      initialSlide: selectedItem,
      pauseOnHover: stopOnHover,
      autoplaySpeed: interval,
      speed: transitionTime,
      draggable,
    };

    return (
      <div
        className={cx(
          [styles.root],
          className,
          highlightedArrows && styles.highlightedArrows,
          postionTeaser && styles.teaserStageContent
        )}
        onClick={wholeAreaIsClickable && this.handleClick}
      >
        <Slider {...settings} ref={this.setRef} beforeChange={this.onChange}>
          {children}
        </Slider>
        {showStatus && (
          <div className={styles.status}>
            <div className={styles.statusText}>
              {this.state.currentIndex}/{React.Children.count(children)}
            </div>
          </div>
        )}
      </div>
    );
  }
}

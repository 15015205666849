/**
 * a debouncer for any quickfired events/functions (scrollHandling f.e.)
 */
export function useDebounce({ func, ms = 200, context = null }) {
  let timer;

  return function(...args) {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(context, args), ms);
  };
}

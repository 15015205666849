// @flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import Icon from 'Components/Icon';
import classNames from 'classnames/bind';
import FlyoutLink from './link';
import FlyoutTeaser from './teaser';
import styles from './styles.scss';
import hstyles from '../styles.scss';
import { getTabByHref } from 'Utils/getContactTab';
import { MODAL_TYPES } from 'Containers/ContactForm/contactFormConstants';

const cx = classNames.bind(styles);
const cxh = classNames.bind(hstyles);

type Props = {
  entry: Object,
  open: boolean,
  history: Object,
  onLinkClick: (entry: Object) => void,
  onMainEntryClick: (entry: Object) => void,
  transitionDelay: boolean,
  children: Node,
  isServicePartnerOnly: boolean,
  isSalesPartnerOnly: boolean,
  isOsbUser: boolean,
};

class Flyout extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.state = {
      hide: true,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) this.setState({ hide: false });

      if (!this.props.open) {
        setTimeout(() => {
          this.setState({ hide: true });
        }, 300);
      }
    }
  }

  componentDidMount() {
    document.body.addEventListener('click', this.handleBodyClick);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleBodyClick);
  }

  handleBodyClick = (e) => {
    if (this.props.open) {
      if (
        !e.target.closest('.' + cx('header__flyout')) &&
        !e.target.closest('.' + cxh('main-nav__item')) &&
        !e.target.closest('.' + cxh('mobile-burger'))
      ) {
        this.handleMainEntryClick();
      }
    }
  };

  handleLinkClick = (lvl3) => {
    const tab = getTabByHref(lvl3.path);

    if (lvl3 && lvl3.label === 'Unsere Standorte') {
      this.handleMainEntryClick();
    }

    if (tab === 1) {
      this.props.onLinkClick(this.props.entry);
      return this.props.updateMultipleUrlQuery({
        modaltype: MODAL_TYPES.SERVICE_APPOINTMENT.name,
        showmodal: 'true',
      });
    }

    if (tab === 3) {
      this.props.onLinkClick(this.props.entry);
      return this.props.updateMultipleUrlQuery({
        modaltype: MODAL_TYPES.REQUEST_FOR_CONSULTATION_FK.name,
        showmodal: 'true',
      });
    }

    if (tab > -1) {
      this.props.onLinkClick(this.props.entry);
      return this.props.onOpenContact(tab);
    }

    // temporary fmm fix
    if (
      !lvl3.path.startsWith('/beratung-und-kauf/fahrzeugboerse/neuwagen') &&
      !lvl3.path.startsWith('/beratung-und-kauf/fahrzeugboerse/gebraucht') &&
      !lvl3.path.startsWith('/beratung-und-kauf/weitere-leistungen/gebrauchtwagenbewertung') &&
      !lvl3.path.startsWith('/beratung-und-kauf/fahrzeugboerse/haendlerzulassung')
    ) {
      this.props.history.push(lvl3.path);
      this.props.onLinkClick(this.props.entry);

      return;
    }

    if (lvl3.path.endsWith('/haendlerzulassung/')) {
      window.location.href = window.location.protocol + '//' + window.location.host + lvl3.path;

      return;
    }

    // MAZ-531 do not call 301, redirect self
    if (lvl3.path.endsWith('/weitere-leistungen/gebrauchtwagenbewertung/')) {
      window.location.href =
        window.location.protocol + '//' + window.location.host + '/gebrauchtwagenbewertung/';

      return;
    }

    window.location.href = window.location.protocol + '//' + window.location.host + lvl3.path;
  };

  handleMainEntryClick = () => {
    this.props.onMainEntryClick(this.props.entry);
  };

  render() {
    const {
      entry,
      open,
      transitionDelay,
      children,
      //isServicePartnerOnly,
      isSalesPartnerOnly,
      forceMobileMenu,
    } = this.props;
    const dfmm = { 'data-force-mobile-menu': forceMobileMenu };

    let entriesLvl2 =
      entry.subentries.filter((subentry) => subentry.subentries && !!subentry.subentries.length) ||
      [];

    // if (isServicePartnerOnly) {
    //   entriesLvl2 = entriesLvl2.map(entry => {
    //     if (entry.label !== 'Termin vereinbaren') {
    //       return entry;
    //     }
    //
    //     let updatedEntry = { ...entry };
    //     updatedEntry.subentries = updatedEntry.subentries.filter(
    //       subentry => subentry.label !== 'Beratung'
    //     );
    //     return updatedEntry;
    //   });
    // }

    if (isSalesPartnerOnly) {
      entriesLvl2 = entriesLvl2.map((entry) => {
        if (entry.label !== 'Termin vereinbaren') {
          return entry;
        }

        let updatedEntry = { ...entry };
        updatedEntry.subentries = updatedEntry.subentries.filter(
          (subentry) => subentry.label !== 'Werkstatt'
        );
        return updatedEntry;
      });
    }

    const flyoutClass = cx('header__flyout', {
      'header__flyout--open': open,
      'header__flyout--transition-delay': transitionDelay,
      'header__flyout--no-teaser': !children,
    });

    return (
      <div className={flyoutClass} style={{ opacity: !open && this.state.hide ? 0 : 1 }} {...dfmm}>
        <div className={cx('relativeWrapper')} {...dfmm}>
          <div className={cx('gr-container')} {...dfmm}>
            <div className={cx('flyout-head')} onClick={this.handleMainEntryClick} {...dfmm}>
              <Icon name="caret-left" className={styles.icon} inline={false} />
              <span>{entry.label}</span>
            </div>
            <ul className={cx('flyout-nav')} {...dfmm}>
              {entriesLvl2.map((lvl2, i) => {
                const entriesLvl3 = lvl2.subentries || [];
                return (
                  <li key={`flyout-lvl2-${i}`} className={cx('flyout-nav__item')} {...dfmm}>
                    {lvl2.label}
                    <ul className={cx('flyout-subnav')}>
                      {entriesLvl3.map((lvl3) => (
                        <FlyoutLink
                          key={lvl3.id}
                          entry={lvl3}
                          parentEntries={[entry, lvl2]}
                          onClick={this.handleLinkClick}
                        />
                      ))}
                    </ul>
                  </li>
                );
              })}
            </ul>
            {children && (
              <FlyoutTeaser
                size={entriesLvl2.length > 2 ? 'small' : 'large'}
                forceMobileMenu={forceMobileMenu}
              >
                {children}
              </FlyoutTeaser>
            )}
          </div>

          <button className={cx('closebtn')} onClick={this.handleMainEntryClick} {...dfmm}>
            <Icon name="close" inline={false} />
          </button>
        </div>
      </div>
    );
  }
}

export default withRouter(Flyout);

import React, { Fragment } from 'react';
import styles from './styles.scss';

export const InfoTextForGoogleCookies = props => {
  return (
    <Fragment>
      <h5>
        {props.type === 'statistik'
          ? 'Google Analytics (Leistungs- und Analyse-Cookies)'
          : 'Google Analytics Audiences (Marketing-Cookies)'}
      </h5>
      <br />
      <h6 className={styles.h7_small_margin}>Beschreibung des Dienstes</h6>
      <br />
      Das ist ein Webanalyse-Dienst. Die Daten können in die Vereinigten Staaten übermittelt werden,
      wo das Datenschutzniveau möglicherweise nicht mit dem in der EU vergleichbar ist. Ein
      angemessenes Datenschutzniveau wird aber durch die Vereinbarung von sog.
      Standarddatenschutzklauseln gemäss Art. 46 Abs. 2 lit. c, Abs. 5 DSGVO. Ausserdem wurden ggf.
      zusätzliche Massnahmen umgesetzt.
      <h6 className={styles.h7}>Verarbeitendes Unternehmen</h6>
      Google Ireland Limited: Google Building Gordon House, 4 Barrow St, Dublin, D04 E5W5, Ireland
      <br />
      Im Folgenden finden Sie die E-Mail-Adresse des Datenschutzbeauftragten des verarbeitenden
      Unternehmens.
      <br />
      <a href="https://support.google.com/policies/contact/general_privacy_form">
        https://support.google.com/policies/contact/general_privacy_form
      </a>
      <br />
      <br />
      {props.type === 'statistik' && (
        <Fragment>
          <ul>
            <li>
              <u>
                <strong>Kategorie</strong>
              </u>
              <ul>
                <li>Leistungs- und Analyse-Cookies</li>
              </ul>
            </li>
            <li>
              <u>
                <strong>Zweck der Daten</strong>
              </u>
              <br />
              Diese Liste stellt die Zwecke der Erhebung und Verarbeitung der Daten dar.
              <ul>
                <li>Analyse</li>
                <li>Optimierung</li>
              </ul>
            </li>
            <li>
              <u>
                <strong>Erhobene Daten</strong>
              </u>
              <br />
              Diese Liste enthält alle (personenbezogenen) Daten, die durch oder bei der Verwendung
              dieses Dienstes erhoben werden.
              <ul>
                <li>Browserinformation</li>
                <li>Datum und Uhrzeit des Besuchs</li>
                <li>Geräteinformationen</li>
                <li>Standortinformation</li>
                <li>IP-Adresse</li>
                <li>Besuchte Seiten</li>
                <li>Verweisende URL</li>
                <li>Nutzungsdaten</li>
                <li>Zustimmungsstatus</li>
              </ul>
            </li>
          </ul>
        </Fragment>
      )}
      {props.type === 'marketing' && (
        <Fragment>
          <ul>
            <li>
              <u>
                <strong>Kategorie</strong>
              </u>
              <ul>
                <li>Marketing-Cookies</li>
              </ul>
            </li>
            <li>
              <u>
                <strong>Zweck der Daten</strong>
              </u>
              <br />
              Diese Liste stellt die Zwecke der Erhebung und Verarbeitung der Daten dar.
              <ul>
                <li>Analyse</li>
                <li>Werbung</li>
                <li>Marketing</li>
                <li>Remarketing</li>
                <li>Optimierung</li>
              </ul>
            </li>
            <li>
              <u>
                <strong>Erhobene Daten</strong>
              </u>
              <br />
              Diese Liste enthält alle (personenbezogenen) Daten, die durch oder bei der Verwendung
              dieses Dienstes erhoben werden.
              <ul>
                <li>Browserinformation</li>
                <li>Datum und Uhrzeit des Besuchs</li>
                <li>Geräteinformationen</li>
                <li>Standortinformation</li>
                <li>Besuchte Seiten</li>
                <li>Verweisende URL</li>
                <li>Nutzungsdaten</li>
                <li>Zustimmungsstatus</li>
              </ul>
            </li>
          </ul>
        </Fragment>
      )}
      <h6 className={styles.h7}>Verwendete Technologien</h6>
      Diese Liste stellt alle Technologien dar, die dieser Dienst zur Datenerhebung verwendet.
      Typische Technologien sind Cookies und Pixel, die im Browser platziert werden.
      <ul>
        <li>Cookies</li>
        <li>Pixel</li>
        <li>Tagging</li>
        <li>Datenmodellierung</li>
      </ul>
      <h6 className={styles.h7}>Rechtsgrundlagen</h6>
      Im Folgenden sind die erforderlichen Rechtsgrundlagen der Datenverarbeitung aufgeführt.
      <ul>
        <li>Art. 6 Abs. 1 s. 1 lit a DSGVO</li>
      </ul>
      <h6 className={styles.h7}>Standort der Verarbeitung</h6>
      Das ist der primäre Standort der Verarbeitung der erhobenen Daten. Wir informieren Sie, falls
      die Informationen in anderen Ländern verarbeitet werden.
      <ul>
        <li>Europäische Union</li>
      </ul>
      <h6 className={styles.h7}>Dauer der Datenspeicherung</h6>
      Die Aufbewahrungsfrist ist die Zeitspanne, in der die erhobenen Daten für die Zwecke der
      Verarbeitung gespeichert werden. Die Daten sind zu löschen, sobald sie für die angegebenen
      Verarbeitungszwecke nicht mehr benötigt werden.
      <ul>
        <li>
          Die Daten sind zu löschen, sobald sie für die angegebenen Verarbeitungszwecke nicht mehr
          benötigt werden.
        </li>
        <li>Maximale Dauer der Cookie-Speicherung: 2 Jahre</li>
      </ul>
      <h6 className={styles.h7}>Vertrieb in Drittländern</h6>
      Der Dienst kann die erhobenen Daten in ein anderes Land übertragen. Bitte beachten, dass
      dieser Dienst die Daten ausserhalb der EU/EWR und in ein Land ohne die erforderlichen
      Datenschutzstandards übermitteln kann. Wenn die Daten in die Vereinigten Staaten übermittelt
      werden, besteht das Risiko, dass Ihre Daten von den US-Behörden ohne rechtliche Abhilfen zu
      Kontroll- und Überwachungszwecken verarbeitet werden. Im Folgenden finden Sie eine Liste der
      Länder, in die die Daten übermittelt werden. Das ist zu unterschiedlichen Zwecken, wie z. B.
      Speicherung oder Verarbeitung möglich.
      <ul>
        <li>Vereinigte Staaten von Amerika</li>
      </ul>
      <h6 className={styles.h7}>Datenempfänger</h6>
      Im Folgenden sind die Empfänger der erhobenen Daten aufgeführt.
      <ul>
        <li>Alphabet Inc., Google LLC, Google Ireland Limited</li>
      </ul>
      <a href="https://policies.google.com/privacy?hl=en">
        Klicken Sie hier, um die Datenschutzrichtlinie des Datenverarbeiters zu lesen.
      </a>
      <br />
      <a href="https://tools.google.com/dlpage/gaoptout?hl=en">
        Klicken Sie hier, um diesen Verarbeiter für alle Domänen zu deaktivieren.
      </a>
      <br />
      <br />
      <br />
    </Fragment>
  );
};

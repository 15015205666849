// @flow
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'Components/Grid';
import Contact from './Contact';
import OpeningHours from './OpeningHours';
import classNames from 'classnames';
import SocialMedia from './SocialMedia';
import DealerModel from 'Stores/DealerModel';
import styles from './styles.scss';
import { footerTracking } from 'Utils/trackingAttributes';
import type { NavigationItem } from 'Stores/NavigationItem';

export default class Footer extends React.Component {
  static propTypes = {
    dealer: PropTypes.instanceOf(DealerModel),
    visible: PropTypes.bool,
    onOpenContact: PropTypes.func,
    showMapClicked: PropTypes.bool,
    setShowMapClicked: PropTypes.func,
  };

  componentDidMount() {
    window.app_version = APP_VERSION;
  }

  addTrailingSlash(href) {
    return `${href}${href.endsWith('/') ? '' : '/'}`;
  }

  render() {
    const { dealer, visible, className } = this.props;

    return (
      <Fragment>
        <div id="fmm-footnotes" />

        <footer
          className={classNames(styles.footer, className)}
          style={{ opacity: visible ? 1 : 0 }}
        >
          {dealer.hasFMMData && <Contact {...this.props} />}
          <Container>
            {dealer.openingHours.length > 0 && <OpeningHours openingHours={dealer.openingHours} />}
            <SocialMedia dealer={dealer} />
            <Row>
              <Col>
                <div className={styles.legal}>
                  {(window.dealer.sitemap || [])
                    .filter((item: NavigationItem) => item.footer && !item.hideInMenu)
                    .map((item) => (
                      <Link
                        className={styles.legalLink}
                        to={this.addTrailingSlash(
                          item.dokType === 'shortcut' && item.link ? item.link.href : item.path
                        )}
                        {...footerTracking(item.label)}
                      >
                        {item.label}
                      </Link>
                    ))}
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </Fragment>
    );
  }
}

// @flow
import React, { Component } from 'react';
import { loadAsync } from 'Utils';

declare var FMM_SNIPPET_BASE_URL_NEW: string;

type Props = {
  query: string,
};

export default class TopCars extends Component<Props> {
  componentDidMount() {
    // we don't reload the script if the remountSnippet is available
    if (window.remountSnippet) return window.remountSnippet();

    loadAsync(`${process.env.FMM_SNIPPET_BASE_URL_NEW}main.css`, true);
    loadAsync(`${process.env.FMM_SNIPPET_BASE_URL_NEW}main.js`, true, true, true);
  }

  render() {
    const { query, headline, cta, dealer } = this.props;

    if (dealer.settings.customTopCars.isActive) {
      return null;
    }

    return (
      <div id={this.props.deeplinkId} style={{ scrollMarginTop: '30px' }}>
        <div
          className="fmm-vehicle-snippet"
          data-dealerid={dealer.demodernDealerId}
          data-fmm-query={query}
          data-cta-copy={cta}
          data-headline-copy={headline}
          id="fmm-snippet"
        />
      </div>
    );
  }
}

import React, { Fragment } from 'react';
import classNames from 'classnames/bind';
import styles from './styles.scss';

const cx = classNames.bind(styles);

export default class ScrollToTop extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hidden: true,
      isCompact: window.innerWidth < 768,
      scrollActive: false,
      lastOffset: 0,
    };
  }

  handleScrollEnd = () => {
    const { hidden } = this.state;
    const offset = window.pageYOffset;
    const activationOffset = 500;

    if (hidden && offset > activationOffset) this.setState({ hidden: false });
    if (!hidden && offset < activationOffset) this.setState({ hidden: true });
  };

  handleScrollStart = () => {
    if (!this.state.scrollActive) {
      this.setState({
        hidden: true,
        isCompact: window.innerWidth < 768,
        scrollActive: true,
        lastOffset: window.pageYOffset,
      });

      setTimeout(this.checkScrollEnded, 300);
    }
  };

  checkScrollEnded = () => {
    if (this.state.lastOffset === window.pageYOffset) {
      this.setState({ scrollActive: false });
    } else {
      this.setState({
        lastOffset: window.pageYOffset,
      });
      setTimeout(this.checkScrollEnded, 300);
    }
  };

  componentDidUpdate(_, prevState) {
    if (!this.state.scrollActive && prevState.scrollActive) {
      this.handleScrollEnd();
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScrollStart, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScrollStart);
  }

  render() {
    const { settings } = this.props;
    const whatsappUrl =
      settings && settings.whatsappWidget && settings.whatsappWidget.isActive
        ? settings.whatsappWidget.whatsappUrl
        : undefined;

    return (
      <button
        className={cx(
          'btnWrapper',
          this.state.hidden ? 'hidden' : undefined,
          whatsappUrl ? 'center' : undefined,
          !this.state.isCompact ? styles.btnWrapperDesktop : undefined
        )}
        onClick={() =>
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          })
        }
      >
        {!this.state.isCompact && (
          <Fragment>
            <div className={styles.leftCircle}></div>
            <div className={styles.textWrapper}>
              <span>Nach oben</span>
              <img
                alt="Icon back to top"
                className={styles.backToTopIconDesktop}
                src={`${process.env.ASSET_ENV_HOST}/assets/img/icon_back_to_top.svg`}
              />
            </div>
            <div className={styles.rightCircle}></div>
          </Fragment>
        )}

        {this.state.isCompact && (
          <div
            className={cx(
              styles.logoWrapper,
              !this.state.isCompact ? styles.logoWrapperDesktop : undefined
            )}
          >
            <img
              alt="Icon back to top"
              className={styles.backToTopIcon}
              src={`${process.env.ASSET_ENV_HOST}/assets/img/icon_back_to_top.svg`}
            />
          </div>
        )}
      </button>
    );
  }
}

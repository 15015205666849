import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';
import FocusedImage from 'Components/FocusedImage';
import AssetModel from 'Stores/AssetModel';
import DealerContact from './dealerContact';
import Button from 'Components/Button';
import Accordion from 'Components/Accordion';
import { getWeekDayDe } from '../../../utils/getDate/index';
import { MODAL_TYPES } from 'Containers/ContactForm/contactFormConstants';
import { withRouter } from 'react-router-dom';
import { withUrlParams } from '../../../utils/withUrlParams';
import cn from 'classnames';

class LocationCard extends React.Component {
  constructor(props) {
    super(props);

    const { branch } = props;
    const image = branch && branch.aboutus && branch.aboutus.image;
    this.asset = createRef();

    if (image) {
      this.asset.current = new AssetModel({
        src: image.thumbnails['600x600'] || image.src,
        alternative: image.alternative,
      });
    }
  }

  getOpeningHours = (openingHoursArray, label, open) => {
    if (!openingHoursArray) return;

    const openingHours = openingHoursArray.filter((line) => 'Montag' !== line.dayOfWeekDe);

    return (
      <div
        className={cn(styles.openingHoursList, {
          [styles.openingHoursListOpen]: open,
        })}
      >
        <div className={styles.openingHoursListName}>{label}</div>
        <div className={styles.openingHoursListDays}>
          {openingHours.map((hours, i) => (
            <div
              key={`${hours.dayOfWeekDe}-${i}`}
              className={cn({
                [styles.dayHighlight]: getWeekDayDe() === hours.dayOfWeekDe,
              })}
            >
              {hours && hours.dayOfWeekDe && hours.dayOfWeekDe.substr(0, 2)}:
            </div>
          ))}
        </div>
        <div className={styles.openingHoursListHours}>
          {openingHours.map((hours, i) => (
            <div
              key={`${hours.dayOfWeekDe}-${i}`}
              className={cn({
                [styles.dayHighlight]: getWeekDayDe() === hours.dayOfWeekDe,
              })}
            >{`${hours.from} - ${hours.to}`}</div>
          ))}
        </div>
      </div>
    );
  };

  getOpeningHoursHeader = (openingHoursArray, label, open) => {
    if (!openingHoursArray) return;

    const dayHighlight = open && getWeekDayDe() === 'Montag';

    const openingHours = open
      ? openingHoursArray.filter((line) => 'Montag' === line.dayOfWeekDe)
      : openingHoursArray.filter((line) => getWeekDayDe() === line.dayOfWeekDe);

    return (
      <div
        className={cn(styles.openingHoursListHeader, {
          [styles.openingHoursListHeaderOpen]: open,
        })}
      >
        <div className={styles.openingHoursListName}>{label}</div>
        <div
          className={cn(styles.openingHoursListDays, {
            [styles.dayHighlight]: dayHighlight,
          })}
        >
          {open ? 'Mo:' : 'Heute:'}
        </div>
        <div
          className={cn(styles.openingHoursListHours, {
            [styles.dayHighlight]: dayHighlight,
          })}
        >
          {openingHours.map((hours, i) => (
            <div key={i}>{`${hours.from} - ${hours.to}`}</div>
          ))}
        </div>
      </div>
    );
  };

  setLeadsDealerId = () => {
    const { setSharedState, branch, dealer } = this.props;

    setSharedState('leadsMimicDealerId', branch.leadsMimicDealerId || dealer.dealerId);
  };

  render() {
    const { branch, isCurrentBranch, outerControlToggle, openedHoursAccordions, dealer, e2e } =
      this.props;

    return (
      <div
        className={cn(styles.locationCard, {
          [styles.currentBranch]:
            dealer.branches.length > 1 && (!!isCurrentBranch || !branch.showLocationLink),
        })}
        data-e2e={e2e}
        id={'locationcard'}
      >
        <div>
          <div className={styles.locationCard__image}>
            <FocusedImage image={this.asset.current} useThumb={false} />
          </div>
          <div
            className={cn(styles.locationCard__changeLocation, {
              [styles.currentBranch__changeLocation]: !!isCurrentBranch || !branch.showLocationLink,
            })}
          >
            {' '}
            {isCurrentBranch && `Standort ${dealer.mainContact.city}`}
            {!isCurrentBranch && !branch.showLocationLink && `Standort ${branch.address.city}`}
            {!isCurrentBranch && branch.showLocationLink && (
              <Button
                href={`https://${branch.domainName}`}
                target="_blank"
                label={'Zum Standort wechseln'}
                disabled={isCurrentBranch}
                data-e2e={'goto_location'}
                resetLineHeight
                small
              />
            )}
          </div>

          <div className={styles.locationCard__contact}>
            <DealerContact branch={branch} />
          </div>
        </div>
        <div
          className={styles.bottomSection}
          data-some-accrodion-open={openedHoursAccordions.length > 0}
        >
          <div className={styles.openingHours}>
            <div className={styles.contact__headerHours}>Öffnungszeiten</div>
            {branch.isSalesPartner && branch.openingHoursForSales && (
              <Accordion
                classNames={{
                  root: styles.accordionInLocationRoot,
                  header: styles.accordionInLocationHeader,
                  label: styles.accordionInLocationLabel,
                  customTransiton:
                    !openedHoursAccordions.includes(`${branch.mazdaId}-sales`) &&
                    styles.closeTransition,
                }}
                label={this.getOpeningHoursHeader(
                  branch.openingHoursForSales,
                  'Verkauf:',
                  openedHoursAccordions.includes(`${branch.mazdaId}-sales`)
                )}
                outerControlToggle={outerControlToggle}
                accordionName={`${branch.mazdaId}-sales`}
                outerControlOpen={openedHoursAccordions.includes(`${branch.mazdaId}-sales`)}
                e2e={'sales'}
                small
              >
                {this.getOpeningHours(
                  branch.openingHoursForSales,
                  '',
                  openedHoursAccordions.includes(`${branch.mazdaId}-sales`)
                )}
              </Accordion>
            )}

            {branch.isServicePartner && branch.openingHoursForServices && (
              <Accordion
                classNames={{
                  root: styles.accordionInLocationRoot,
                  header: styles.accordionInLocationHeader,
                  label: styles.accordionInLocationLabel,
                  customTransiton:
                    !openedHoursAccordions.includes(`${branch.mazdaId}-service`) &&
                    styles.closeTransition,
                }}
                label={this.getOpeningHoursHeader(
                  branch.openingHoursForServices,
                  'Service:',
                  openedHoursAccordions.includes(`${branch.mazdaId}-service`)
                )}
                outerControlToggle={outerControlToggle}
                accordionName={`${branch.mazdaId}-service`}
                outerControlOpen={openedHoursAccordions.includes(`${branch.mazdaId}-service`)}
                e2e={'service'}
                small
              >
                {this.getOpeningHours(
                  branch.openingHoursForServices,
                  '',
                  openedHoursAccordions.includes(`${branch.mazdaId}-service`)
                )}
              </Accordion>
            )}
          </div>
          <div className={styles.locationCard__contactButtons}>
            {' '}
            {branch.isSalesPartner && (
              <Button
                label={'Beratungstermin'}
                href={
                  isCurrentBranch || !!branch.leadsMimicDealerId
                    ? undefined
                    : `https://${branch.domainName}/?modaltype=REQUEST_FOR_CONSULTATION_FK&showmodal=true`
                }
                onClick={
                  isCurrentBranch || !!branch.leadsMimicDealerId
                    ? () => {
                        this.setLeadsDealerId();
                        this.props.updateMultipleUrlQuery({
                          modaltype: MODAL_TYPES.REQUEST_FOR_CONSULTATION_FK.name,
                          showmodal: 'true',
                        });
                      }
                    : undefined
                }
                data-e2e={'goto_beratungstermin'}
                resetLineHeight
                small
              />
            )}
            {branch.isServicePartner && (
              <Button
                label={'Werkstatttermin'}
                href={
                  isCurrentBranch || !!branch.leadsMimicDealerId
                    ? undefined
                    : `https://${branch.domainName}/?modaltype=ONLINE_SERVICE_BOOKING&showmodal=true`
                }
                onClick={
                  isCurrentBranch || !!branch.leadsMimicDealerId
                    ? () => {
                        this.setLeadsDealerId();
                        this.props.updateMultipleUrlQuery({
                          modaltype: MODAL_TYPES.ONLINE_SERVICE_BOOKING.name,
                          showmodal: 'true',
                        });
                      }
                    : undefined
                }
                data-e2e={'goto_werkstatttermin'}
                resetLineHeight
                small
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

LocationCard.propTypes = {
  imageSrc: PropTypes.string,
  branch: PropTypes.object,
  isCurrentBranch: PropTypes.bool,
  openedHoursAccordions: PropTypes.array,
  dealer: PropTypes.object,
};

export default withRouter(withUrlParams(LocationCard));

export const useScroll = id => {
  const target = document.querySelector(`#${id}`);

  if (target) target.scrollIntoView({ behavior: 'smooth' });
};

// IE does not provide Element.scrollBy
export const useScrollBy = (element, x, y) => {
  if (!element) {
    return;
  }

  if (Element.prototype.scrollBy) {
    element.scrollBy(x, y);
  } else {
    if (typeof x === 'number') {
      element.scrollLeft += x;
    }

    if (typeof y === 'number') {
      element.scrollTop += y;
    }
  }
};

// IE does not provide Element.scrollTo
export const useScrollTo = (element, x, y) => {
  if (!element) {
    return;
  }

  if (Element.prototype.scrollTo) {
    element.scrollTo(x, y);
  } else {
    if (typeof x === 'number') {
      element.scrollLeft = x;
    }

    if (typeof y === 'number') {
      element.scrollTop = y;
    }
  }
};

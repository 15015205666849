// @flow
import BaseModel from 'Stores/BaseModel';

export default class CareerModel extends BaseModel {
  contactPhone: ?string = null;
  contactEmail: ?string = null;
  text: string = '';
  title: string = '';

  constructor(data: Object = {}) {
    super();
    this.hydrate(data);
  }
}

// @flow
import axios from 'axios';
import BaseModel from 'Stores/BaseModel';
import ListModel from 'Stores/ListModel';
import { slugify } from 'Utils/index';

declare var API_BASE_URL: string;

export default class AssetModel extends BaseModel {
  id: number = 0;
  title: string = '';
  mimeType = '';
  description: string = '';
  alt: string = '';
  file: ?File = null;
  src: string = '';
  focus: {
    x: number,
    y: number,
  } = { x: 0, y: 0 };
  dim: number = 0;
  blur: boolean = false;
  isStock: boolean = false;
  thumbnails: {
    [string]: string,
  } = {};

  constructor(data: ?Object) {
    super();
    if (data) {
      this.hydrate(data);
    }

    // TODO Unify naming to alt in backend
    this.alt = data ? data.alternative : '';
  }

  static SIZE = {
    S: '320x240',
    M: '600x600',
    L: '1024x1024',
  };

  getThumbnail(size: string = 'S') {
    return this.thumbnails && this.thumbnails[AssetModel.SIZE[size]];
  }

  toListItem() {
    return {
      id: this.id,
      headline: this.title || 'Unbenannt',
      subline: this.description || 'keine',
      image: this,
      model: this,
    };
  }

  save = () => {
    return axios
      .patch(`${API_BASE_URL}/assets/${this.id}/`, {
        title: this.title,
        alternative: this.title,
        description: this.description,
        focus: this.focus,
      })
      .then(res => {
        return new AssetModel(res.data);
      });
  };

  upload = () => {
    const extension = this.file ? this.file.name.split('.').pop() : '';
    const newFilename = `${slugify(this.title)}-${Date.now()}.${extension}`;

    const data: FormData = new FormData();
    data.append('qqfile', this.file, newFilename);
    data.append('title', this.title);
    data.append('alternative', this.title);
    data.append('description', this.description);
    data.append('focus[x]', this.focus.x.toString());
    data.append('focus[y]', this.focus.y.toString());

    return axios.post(`${API_BASE_URL}/assets/`, data).then(res => {
      return new AssetModel(res.data);
    });
  };

  delete = () => {
    return axios({
      method: 'delete',
      url: `${API_BASE_URL}/assets/${this.id}/`,
      handleLocal: true,
    });
  };

  shouldUseThumb = () => {
    return window.outerWidth < 800 ? 'L' : undefined;
  };

  toJSON = () => {
    return {
      id: this.id,
      title: this.title,
      description: this.description,
      mimeType: this.mimeType,
      src: this.src,
      focus: this.focus,
      thumbnails: this.thumbnails,
    };
  };

  static getAll = (params: Object = {}, type: string = 'assets') => {
    return axios({
      method: 'get',
      url: `${API_BASE_URL}/${type}/`,
      loadingSpinner: false,
      params,
    }).then(res => {
      return {
        total: res.data.meta.total,
        offset: res.data.meta.offset,
        limit: res.data.meta.limit,
        list: new ListModel(res.data.data, AssetModel),
      };
    });
  };
}

// @flow
import React, { Fragment } from 'react';
import classNames from 'classnames/bind';
import { Container, Row, Col } from 'Components/Grid';
import RichText from 'Components/RichText';
import styles from './styles.scss';
import { getForeground, hasDarkBackground } from 'Utils/getColor/index';
import ConsumptionData from 'Components/ConsumptionData';
const cx = classNames.bind(styles);

type Background = 'white' | 'default';

type Props = {
  id?: string,
  headline?: string,
  text?: string,
  children?: Node,
  background: Background,
  deeplinkId?: number,
};

export default class TextBlock extends React.Component<Props> {
  static defaultProps = {
    background: 'default',
  };

  render() {
    const { id, headline, text, children, background, deeplinkId, consumptionText } = this.props;

    return (
      <Fragment>
        <div id={deeplinkId || id} className={cx(styles.contentText, { [background]: true })}>
          <Container>
            <Row align="center">
              <Col cols={12} m={10} l={8}>
                {headline && (
                  <h2
                    className={cx(styles.headline, getForeground(background), {
                      [styles.overwritep]: hasDarkBackground(background),
                    })}
                    dangerouslySetInnerHTML={{ __html: headline }}
                  />
                )}
                {text && (
                  <RichText content={text} hasDarkBackground={hasDarkBackground(background)} />
                )}
                {children}
              </Col>
            </Row>
          </Container>
        </div>

        {consumptionText && <ConsumptionData consumptionText={[consumptionText]} />}
      </Fragment>
    );
  }
}

export type ContactFormState = {
  errors: { [string]: ?string },
  type: string,
  model: string,
  title: string,
  firstName: string,
  lastName: string,
  street: string,
  houseNumber: string,
  postalCode: string,
  city: string,
  country: string,
  dealer: string,
  telephone: string,
  email: string,
  termsAccepted: boolean,
  showTerms: boolean,
  emailOptIn: boolean,
  telephoneOptIn: boolean,
  smsOptIn: boolean,
  promotionalTermsAccepted: boolean,
  showPromotionalTerms: boolean,
  sent: boolean,
  market: string,
  campaign: string,
  campaignType: string,
  language: string,
  assignToDealer: boolean,
  organisation: string,
  loading: boolean,
};

export const CONTACT_TYPES = [
  'ANGEBOTSANFRAGE',
  'WERKSTATTTERMIN',
  'PROBEFAHRT',
  'BERATUNGSTERMIN',
  'KONFIGURIEREN',
  'SERVICEANFRAGE',
  'SERVICEKONTAKT',
  'ONLINE_SERVICE_BOOKING',
];

export const MODAL_TYPES = {
  REQUEST_FOR_PROPOSAL: { name: 'REQUEST_FOR_PROPOSAL', contactType: 'ANGEBOTSANFRAGE' },
  SERVICE_APPOINTMENT: { name: 'SERVICE_APPOINTMENT', contactType: 'WERKSTATTTERMIN' },
  TEST_DRIVE: { name: 'TEST_DRIVE', contactType: 'PROBEFAHRT' },
  REQUEST_FOR_CONSULTATION: { name: 'REQUEST_FOR_CONSULTATION', contactType: 'BERATUNGSTERMIN' },
  REQUEST_FOR_CONSULTATION_FK: {
    name: 'REQUEST_FOR_CONSULTATION_FK',
    contactType: 'BERATUNGSTERMIN',
  },
  REQUEST_FOR_CONSULTATION_WS: {
    name: 'REQUEST_FOR_CONSULTATION_WS',
    contactType: 'BERATUNGSTERMIN',
  },
  CAR_CONFIGURATOR: { name: 'CAR_CONFIGURATOR', contactType: 'KONFIGURIEREN' },
  TOPCARS: { name: 'TOPCARS', contactType: 'TOPCARS' },
  ONLINE_SERVICE_BOOKING: { name: 'ONLINE_SERVICE_BOOKING', contactType: 'ONLINE_SERVICE_BOOKING' },
  SERVICE_BOOKING_FALLBACK: {
    name: 'SERVICE_BOOKING_FALLBACK',
    contactType: 'SERVICE_BOOKING_FALLBACK',
  },
  SERVICE_REQUEST: { name: 'SERVICE_REQUEST', contactType: 'SERVICEANFRAGE' },
  SERVICE_CONTACT: { name: 'SERVICE_CONTACT', contactType: 'SERVICEKONTAKT' },
  HEADER_WERKSTATTTERMIN: { name: 'HEADER_WERKSTATTTERMIN', contactType: 'HEADER_WERKSTATTTERMIN' },
  FLYOUT_WERKSTATTTERMIN: { name: 'FLYOUT_WERKSTATTTERMIN', contactType: 'FLYOUT_WERKSTATTTERMIN' },
  SERVICE_OSR: { name: 'SERVICE_OSR', contactType: 'SERVICE_OSR' },
  REQUEST_FOR_CONSULTATION_OSBFB: {
    name: 'REQUEST_FOR_CONSULTATION_OSBFB',
    contactType: 'BERATUNGSTERMIN',
  },
};

export const CUSTOMER_TYPES = [
  { value: 'private', label: 'Ich bin Privatkunde' },
  { value: 'business', label: 'Ich bin Gewerbekunde' },
];

// see also:
// - app/web/typo3conf/ext/mazda_api/Configuration/TCA/tx_mazdaapi_domain_model_offer.php
// - app/web/typo3conf/ext/mazda_api/Classes/LinkBrowser/ContactFormLinkHandler.php
export const MAZDA_CAR_LINES = [
  { value: 'MAZDA2_2022', label: 'MAZDA2' },
  { value: 'MAZDA2 Hybrid', label: 'MAZDA2 Hybrid' },
  { value: 'MAZDA3_2022', label: 'MAZDA3' },
  { value: 'MAZDA6_2021', label: 'MAZDA6' },
  { value: 'CX-30_2022', label: 'MAZDA CX-30' },
  { value: 'MAZDA CX-60', label: 'MAZDA CX-60' },
  { value: 'MAZDA CX-60 diesel', label: 'MAZDA CX-60 Diesel' },
  { value: 'MAZDA CX-60 hybrid', label: 'MAZDA CX-60 Plug-in Hybrid' },
  { value: 'CX-5_2022', label: 'MAZDA CX-5' },
  { value: 'MX-5 ST_2022', label: 'MAZDA MX-5 Roadster' },
  { value: 'MX-5 RF_2022', label: 'MAZDA MX-5 RF' },
  { value: 'MAZDA MX-30_2022', label: 'MAZDA MX-30' },
  { value: 'Mazda CX-80', label: 'MAZDA CX-80' },
];

export const OTHER_CAR_LINES = [
  { value: 'other_mazda', label: 'Sonstiges Mazda-Modell' },
  { value: 'other', label: 'Sonstige Marke' },
];

export const ALL_CAR_LINES = [...MAZDA_CAR_LINES, ...OTHER_CAR_LINES];

export const FORM_SALUTATIONS = [
  { value: 'Mr', label: 'Herr' },
  { value: 'Mrs', label: 'Frau' },
];

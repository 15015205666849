import React from 'react';
import Button from 'Components/Button';
import PropTypes from 'prop-types';
import { getTabByTarget, tabTypes } from 'Utils/getContactTab';
import { exchangeLabelIfNecessary, exchangeTrackingLabelIfNecessary } from 'Utils/getButtonLabel';
import onContactButtonClick from 'Utils/onContactButtonClick';
import { validButtonTypes } from 'Utils';
import { withUrlParams } from 'Utils/withUrlParams';
import { withRouter } from 'react-router-dom';
import { getAdjustedModalType } from 'Utils/getModalType';

class CallToAction extends React.Component {
  static propTypes = {
    additional: PropTypes.string,
    href: PropTypes.string,
    label: PropTypes.string,
    light: PropTypes.bool,
    mazdaForm: PropTypes.bool,
    model: PropTypes.string,
    small: PropTypes.bool,
    onOpenContact: PropTypes.func,
    openContactModal: PropTypes.bool,
    trackingLabel: PropTypes.string,
    trackingPushCode: PropTypes.string,
    buttonType: PropTypes.oneOf(validButtonTypes),
    type: PropTypes.oneOf(tabTypes),
    disableAdaptiveLabel: PropTypes.bool,
    disableAdaptiveTracking: PropTypes.bool,
  };

  static defaultProps = {
    additional: null,
    href: null,
    label: '',
    light: false,
    mazdaForm: false,
    model: '',
    target: null,
    onOpenContact: () => {},
    openContactModal: true,
    small: false,
    trackingLabel: null,
    buttonType: 'primary',
    type: null,
  };

  render() {
    const {
      additional,
      label,
      mazdaForm,
      model,
      onOpenContact,
      small,
      trackingLabel,
      trackingPushCode,
      buttonType,
      target,
      type,
      light,
      location,
      scrollToDeeplinkId,
      disableAdaptiveLabel,
      disableAdaptiveTracking,
      onClickMain,
    } = this.props;
    const tabIndex = getTabByTarget(type);
    const track = !!(additional && additional === 'iframeContent');
    const openContactModalCallback = onContactButtonClick(
      !mazdaForm,
      () => onOpenContact && onOpenContact(tabIndex, model)
    );
    let { href } = this.props;

    const hrefIsCurrentLocation = href && href.includes(location.pathname);

    let onClick = null;

    if (tabIndex > -1) {
      onClick = openContactModalCallback;

      // do not follow links when a contact modal should be opened while
      // <Button>s `onClick` has no event for primary typed buttons.
      href = null;
    }

    if (hrefIsCurrentLocation) {
      onClick = () => {
        setTimeout(() => {
          scrollToDeeplinkId();
        }, 100);
      };
    }

    const { isOsbUser, isSalesPartner, isServicePartner } = window.dealer.settings;
    const adjustedModalType = getAdjustedModalType({
      isOsbUser,
      isSalesPartner,
      isServicePartner,
      initialModalType: undefined,
      targetHref: href,
    });

    return (
      <Button
        href={href}
        label={
          !disableAdaptiveLabel && href && href.includes('?')
            ? exchangeLabelIfNecessary(label, adjustedModalType)
            : label
        }
        light={light}
        onClick={onClickMain ? onClickMain : onClick}
        small={small}
        target={target}
        track={track}
        trackingLabel={
          disableAdaptiveTracking
            ? trackingLabel
            : exchangeTrackingLabelIfNecessary(trackingLabel, adjustedModalType)
        }
        trackingPushCode={trackingPushCode}
        type={buttonType}
      />
    );
  }
}

export default withRouter(withUrlParams(CallToAction));

// @flow
import React from 'react';
import classNames from 'classnames/bind';
import styles from './styles.scss';

const cx = classNames.bind(styles);

type Background = 'white' | 'default';

type Props = {
  children: Node,
  className?: string,
  background: Background,
  footerMargin?: boolean,
  topMargin?: boolean,
  deeplinkId?: number,
};

export default class DefaultPage extends React.Component<Props> {
  static defaultProps = {
    background: 'default',
  };

  render() {
    const { children, className, background, footerMargin, topMargin, deeplinkId } = this.props;

    const rootClass = cx('page', className, {
      [background]: true,
      footerMargin: footerMargin,
      topMargin: topMargin,
    });

    return (
      <div className={rootClass} id={deeplinkId}>
        {children}
      </div>
    );
  }
}

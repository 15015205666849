import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './containers/App';
import './styles/global.scss';
import { enableTracking } from 'Utils';

enableTracking();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App tab="home" />);

// @flow
import BaseModel from 'Stores/BaseModel';
import ListModel from 'Stores/ListModel';
import { validateLength, isInvalid } from 'Utils/Validators';
import axios from 'axios';
import { withInheritQueryParam } from 'Utils/withInheritQueryParam';
import { conditionalMutation, withoutId } from 'Utils/mutateObject';

export default class FootnoteModel extends BaseModel {
  id: number = 0;
  global: boolean = false;
  description: string = '';
  text: string = '';
  isGeneric: boolean = false;
  number: string = '';

  constructor(data: Object = {}) {
    super();
    this.hydrate(data);
  }

  getKey = (footnotes?: ListModel) => {
    const prefix = !this.global ? 'FN' : 'G';

    if (this.isNew() && footnotes) {
      const ids = footnotes.map(footnote => footnote.id);
      const nextId = ids.length > 0 ? Math.max(...ids) + 1 : 1;
      return `${prefix}${nextId}`;
    }

    return `${prefix}${this.id}`;
  };

  toListItem() {
    return {
      id: this.id,
      headline: `${this.getKey()} - ${this.description}`,
      subline: this.text,
      model: this,
      isGeneric: this.isGeneric,
    };
  }

  valid = () => {
    this.errors = {};
    validateLength(this.errors, 'description', this.description, 1, 60);
    validateLength(this.errors, 'text', this.text, 1, 2500);
    return !isInvalid(this.errors);
  };

  save = (inherit?: boolean) => {
    const data = this.toJSON();

    if (this.id > 0 && !inherit) {
      return axios
        .patch(`/api/v1/footnotes/${this.id}/`, data)
        .then(res => new FootnoteModel(res.data));
    }

    return axios
      .post(
        withInheritQueryParam('/api/v1/footnotes/', inherit),
        conditionalMutation(withoutId, inherit, data)
      )
      .then(res => new FootnoteModel(res.data));
  };

  delete = () => {
    return axios.delete(`/api/v1/footnotes/${this.id}/`);
  };

  getDeleteMsg = () => {
    return `Möchten Sie die Fußnote <strong>${this.getKey()}</strong> wirklich löschen? Dieser
    Vorgang kann nicht rückgängig gemacht werden.`;
  };

  toJSON = () => {
    return {
      id: this.id,
      global: this.global,
      description: this.description,
      text: this.text,
      isGeneric: this.isGeneric,
    };
  };

  static getAll = () => {
    return axios({
      method: 'get',
      url: '/api/v1/footnotes/',
    }).then((res: { data: Array<Object> }) => new ListModel(res.data, FootnoteModel));
  };

  static getPageFootnotes = pageId => {
    return axios({
      method: 'get',
      url: `/api/v1/pages/${pageId}`,
    }).then(
      (res: { data: Array<Object> }) =>
        res.data.content.find(item => item.type === 'plugin_footnotes').footnotes
    );
  };
}

// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import Icon from 'Components/Icon';
import { Row } from 'Components/Grid';
import DealerModel from 'Stores/DealerModel';
import DealerSettingsModel from 'Stores/DealerSettingsModel';
import styles from './styles.scss';
import { footerTracking } from 'Utils/trackingAttributes';

type Props = {
  dealer: DealerModel,
};

export default class SocialMedia extends React.Component<Props> {
  renderSocialMediaLink(link: Object) {
    return (
      <a
        className={styles.link}
        key={link.key}
        href={link.href}
        target="_blank"
        rel="noopener noreferrer"
        {...footerTracking(link.key)}
      >
        <Icon name={`social-${link.key}`} inline={false} />
      </a>
    );
  }

  render() {
    const settings: DealerSettingsModel = this.props.dealer.settings;
    const links = settings.getSocialMediaLinks();

    return (
      <div className={styles.root}>
        <Row>
          <div className={styles.wrapper}>
            {
              <div className={styles.vacancies}>
                <Link to="/ueber-uns/autohaus/karriere" {...footerTracking('Stellenanzeigen')}>
                  Stellenanzeigen
                </Link>
              </div>
            }
            <div className={styles.links}>
              {links.map(link => this.renderSocialMediaLink(link))}
            </div>
          </div>
        </Row>
      </div>
    );
  }
}

import React, { Component } from 'react';
import styles from './styles.scss';
import { Col, Container, Row } from 'Components/Grid';
import FootnoteList from 'Components/FootnoteList';
import cn from 'classnames';
import PropTypes from 'prop-types';
import DealerModel from 'Stores/DealerModel';

export default class Footnotes extends Component {
  static propTypes = {
    background: PropTypes.string,
    footnotes: PropTypes.array,
    sharedState: PropTypes.object,
    setSharedState: PropTypes.func,
    dealer: PropTypes.instanceOf(DealerModel),
  };

  constructor(props) {
    super(props);

    this.placeholder = document.querySelector('#fmm-footnotes');
    if (this.placeholder) {
      // remove placeholder for actual footnotes implementation BEFORE mounting
      this.placeholder.innerHTML = ''; // cleanup in case the vue-snippet doesn't on unmount
      this.placeholder.setAttribute('id', 'fmm-footnotes_placeholder');
    }
  }

  componentWillUnmount() {
    if (!this.placeholder) return;

    this.placeholder.setAttribute('id', 'fmm-footnotes');
  }

  render() {
    const { background, footnotes, sharedState } = this.props;
    let serviceFootnotes = false;
    if (sharedState && sharedState.serviceFootnotes) {
      serviceFootnotes = sharedState.serviceFootnotes;
    }

    if (!footnotes || footnotes.length === 0) {
      return null;
    }

    // generic footnotes may have different type
    const genericFootnotes = footnotes.filter(note => note.isGeneric);
    const genericFootnotesWithMarker = genericFootnotes.filter(note => !!note.number);
    const genericFootnotesWithoutMarker = genericFootnotes.filter(note => !note.number);
    // filter all footnotes for generic ones (diff of all and generic)
    const mainFootnotes = footnotes.filter(footnote => !genericFootnotes.includes(footnote));

    const containerClasses = cn(styles.root, {
      [background]: true,
    });

    return (
      <div className={containerClasses} id="footnotes">
        <Container>
          {!!genericFootnotesWithMarker.length && (
            <Row className={styles.footnoteRowMt10}>
              <Col>
                <FootnoteList footnotes={genericFootnotesWithMarker} />
              </Col>
            </Row>
          )}

          {!!genericFootnotesWithoutMarker.length && (
            <Row className={styles.footnoteRowMt10}>
              <Col>
                <FootnoteList footnotes={genericFootnotesWithoutMarker} />
              </Col>
            </Row>
          )}

          {!!mainFootnotes.length && (
            <Row className={styles.footnoteRowMt10}>
              <Col>
                <FootnoteList footnotes={mainFootnotes} />
              </Col>
            </Row>
          )}

          {serviceFootnotes && serviceFootnotes.length > 0 && (
            <Row className={styles.footnoteRowMt10}>
              <Col>
                <FootnoteList footnotes={serviceFootnotes} />
              </Col>
            </Row>
          )}

          <div id="fmm-footnotes" className={styles.fmmFootnotes} />
        </Container>
      </div>
    );
  }
}

Footnotes.defaultProps = {
  footnotes: [],
};

import { useScroll } from 'Utils/useScroll';

export const isDataAnchor = target => target.getAttribute('data-anchor') === 'true';

export const useAnchor = props => evt => {
  if (!isDataAnchor(evt.target)) {
    return;
  }

  evt.preventDefault();

  const { history, location } = props;
  const href = evt.target.getAttribute('href');

  if (location.hash !== href) {
    history.push(href);
  }

  useScroll(href.slice(1));
};

exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@keyframes _2KPBqG7WX14zB-zgAYFe1U{from{transform:translate3d(0, 1rem, 0);visibility:visible;opacity:0}to{transform:translate3d(0, 0, 0);opacity:1}}@keyframes HHRcnIdZJji4jkE9qzWxS{from{visibility:visible;opacity:0}to{opacity:1}}@keyframes _1gRGb1El5Qf2ieY60F74Dr{0%{filter:blur(3.125rem)}25%{filter:blur(1.25rem)}100%{filter:blur(0)}}@media screen and (min-width: 320px){._1H5l8Eivf7slNbxJ4ZG3C_{padding-top:calc(1rem * 2);padding-bottom:calc(1rem * 2)}}@media screen and (min-width: 768px){._1H5l8Eivf7slNbxJ4ZG3C_{padding-top:calc(2rem * 2);padding-bottom:calc(2rem * 2)}}._1rBbVjMSPq5psyFNXIUdTI{background-color:#fff}._28FZQdE6dIuebSZH3ul2GO{background-color:#f5f5f5}@media screen and (min-width: 320px){._3z5mRbgVwuZfCUvREaFrpA{margin-top:calc(1rem * 1.5);margin-bottom:calc(1rem * 0.5)}}@media screen and (min-width: 768px){._3z5mRbgVwuZfCUvREaFrpA{margin-top:calc(2rem * 1.5);margin-bottom:calc(2rem * 0.5)}}._3z5mRbgVwuZfCUvREaFrpA._2gVkP1mxBEilWkG2L2weVx{margin-top:0}.ohSiI8UuS1tFsqHv8jTXo{position:relative;box-shadow:inset -40px 0 20px -20px rgba(127,127,127,0.35)}@media screen and (min-width: 1024px){.ohSiI8UuS1tFsqHv8jTXo{box-shadow:none}}@media screen and (min-width: 320px){._22m4dw6wtzdPobEISd1buO{margin-bottom:calc(1rem * 1)}}@media screen and (min-width: 768px){._22m4dw6wtzdPobEISd1buO{margin-bottom:calc(2rem * 1)}}@media screen and (min-width: 320px){._2yyd8W_9aiuyp238mZ9gCa{margin-top:calc(1rem * 1)}}@media screen and (min-width: 768px){._2yyd8W_9aiuyp238mZ9gCa{margin-top:calc(2rem * 1)}}@media screen and (min-width: 320px){._29reVGlJZcbp8DV4UQSCh3{margin-top:calc(1rem * 1)}}@media screen and (min-width: 768px){._29reVGlJZcbp8DV4UQSCh3{margin-top:calc(2rem * 1)}}@media screen and (min-width: 320px){._29reVGlJZcbp8DV4UQSCh3>div:not(:last-child){margin-bottom:calc(1rem * 1)}}@media screen and (min-width: 768px){._29reVGlJZcbp8DV4UQSCh3>div:not(:last-child){margin-bottom:calc(2rem * 1)}}\n", ""]);

// exports
exports.locals = {
	"root": "_1H5l8Eivf7slNbxJ4ZG3C_",
	"whiteBg": "_1rBbVjMSPq5psyFNXIUdTI",
	"greyBg": "_28FZQdE6dIuebSZH3ul2GO",
	"headline": "_3z5mRbgVwuZfCUvREaFrpA",
	"firstHeadline": "_2gVkP1mxBEilWkG2L2weVx",
	"tableContainer": "ohSiI8UuS1tFsqHv8jTXo",
	"footnoteTableRow": "_22m4dw6wtzdPobEISd1buO",
	"footnoteRowMt10": "_2yyd8W_9aiuyp238mZ9gCa",
	"fmmFootnotes": "_29reVGlJZcbp8DV4UQSCh3",
	"slideinup": "_2KPBqG7WX14zB-zgAYFe1U",
	"fadein": "HHRcnIdZJji4jkE9qzWxS",
	"blur": "_1gRGb1El5Qf2ieY60F74Dr"
};
import React from 'react';
import queryString from 'query-string';
import { parse } from 'qs';

// this HOC works only in combination with withRouter
export function withUrlParams(WrappedComponent, selectData) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        scrollAttempt: 0,
        positionOk: false,
      };
    }

    updateOneUrlQuery = (value, query) => {
      const allowedUrlQueries = [
        'model',
        'modelDeeplink',
        'vehicleModel',
        'clientType',
        'showmodal',
        'modaltype',
        'activetab',
      ];

      if (!allowedUrlQueries.includes(query)) {
        return;
      }

      const queryParams = parse(this.props.location.search.replace('?', ''));

      switch (query) {
        case 'model':
        case 'modelDeeplink':
        case 'vehicleModel':
          queryParams.carmodel = value;
          break;
        case 'clientType':
          queryParams.clienttype = value;
          break;
        default:
          queryParams[query] = value;
      }

      this.setUrlQuery(queryParams);
    };

    updateMultipleUrlQuery = newQuery => {
      const currentQuery = parse(this.props.location.search.replace('?', ''));
      this.setUrlQuery({ ...currentQuery, ...newQuery });
    };

    setUrlQuery = queries => {
      const current = this.props.history;
      this.props.history.push({
        pathname: current.location.pathname,
        search: `?${new URLSearchParams(queries).toString()}`,
      });
    };

    scrollToDeeplinkId = withPositionCheck => {
      const { deeplinkid } = queryString.parse(this.props.location.search);
      if (deeplinkid) {
        this.scrollToId(deeplinkid, withPositionCheck);
      }
    };

    restartScrollIfNecessary = id => {
      const { scrollAttempt, positionOk } = this.state;

      this.setState({ scrollAttempt: scrollAttempt + 1 });

      if (scrollAttempt > 10 || positionOk) {
        return;
      }

      setTimeout(() => {
        const scrollTarget = document.getElementById(id);

        if (scrollTarget) {
          const rect = scrollTarget.getBoundingClientRect();

          if (rect && (rect.top > 1 || rect.height === 0)) {
            this.scrollToId(id, true);
          } else {
            this.setState({ positionOk: true });
          }
        }
      }, 200);
    };

    scrollToId = (id, withPositionCheck) => {
      const scrollTarget = document.getElementById(id);

      if (scrollTarget) {
        scrollTarget.scrollIntoView({ behavior: 'smooth' });

        if (withPositionCheck) {
          setTimeout(() => this.restartScrollIfNecessary(id), 1000);
        }
      }
    };

    render() {
      return (
        <WrappedComponent
          urlParams={queryString.parse(this.props.location.search)}
          location={queryString.parse(this.props.location)}
          scrollToDeeplinkId={this.scrollToDeeplinkId}
          updateMultipleUrlQuery={this.updateMultipleUrlQuery}
          updateOneUrlQuery={this.updateOneUrlQuery}
          setUrlQuery={this.setUrlQuery}
          {...this.props}
        />
      );
    }
  };
}

exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@keyframes aULxy00X_sXFPg32LtiPR{from{transform:translate3d(0, 1rem, 0);visibility:visible;opacity:0}to{transform:translate3d(0, 0, 0);opacity:1}}@keyframes _19rytETYArOt13Rp5XfPKB{from{visibility:visible;opacity:0}to{opacity:1}}@keyframes N2J-ijZaLQcw25oHnWIOB{0%{filter:blur(3.125rem)}25%{filter:blur(1.25rem)}100%{filter:blur(0)}}@media screen and (min-width: 320px){._2TUkQyw-NogEDwdvQO2Jmr{padding-top:calc(1rem * 2)}}@media screen and (min-width: 768px){._2TUkQyw-NogEDwdvQO2Jmr{padding-top:calc(2rem * 2)}}._3MA4f4tsF0KdJAaySOTJgd{font-family:MazdaTypeBold, sans-serif;font-size:0.875rem;letter-spacing:0.1188rem;line-height:1.25rem;text-transform:uppercase;color:#f5f5f5;border-bottom:0.0625rem solid #9a9a9a;padding-bottom:1rem;margin-bottom:1rem}._3_GrcjrF-iTPVTOoTk_tpA{display:flex;font-family:MazdaTypeBold, sans-serif;font-size:0.75rem;letter-spacing:0.125rem;text-transform:uppercase;color:#9a9a9a;margin-bottom:1rem;line-height:1.25rem}._3_GrcjrF-iTPVTOoTk_tpA:last-child{margin-bottom:0}.ejF1jfPyVo9ve-p3yiy6y{flex:1}._2NH8gpRB3j6zeDIZfNh0rT{flex:1;text-align:right}._12jiJXHYdYMs8goScOg9xL{font-family:MazdaTypeRegular, sans-serif;font-size:0.75rem;line-height:1.25rem;color:#9a9a9a}@media screen and (min-width: 320px){._12jiJXHYdYMs8goScOg9xL{padding-top:calc(1rem * 1)}}@media screen and (min-width: 768px){._12jiJXHYdYMs8goScOg9xL{padding-top:calc(2rem * 1)}}@media screen and (min-width: 768px){._12jiJXHYdYMs8goScOg9xL{font-size:0.875rem;line-height:1.5rem}}\n", ""]);

// exports
exports.locals = {
	"entry": "_2TUkQyw-NogEDwdvQO2Jmr",
	"headline": "_3MA4f4tsF0KdJAaySOTJgd",
	"body": "_3_GrcjrF-iTPVTOoTk_tpA",
	"days": "ejF1jfPyVo9ve-p3yiy6y",
	"times": "_2NH8gpRB3j6zeDIZfNh0rT",
	"legal": "_12jiJXHYdYMs8goScOg9xL",
	"slideinup": "aULxy00X_sXFPg32LtiPR",
	"fadein": "_19rytETYArOt13Rp5XfPKB",
	"blur": "N2J-ijZaLQcw25oHnWIOB"
};
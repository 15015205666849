import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { parse } from 'qs';
import axios from 'axios';
import MainStore from 'Stores/MainStore';
import EkiContentRenderer from '../../components/EkiContentRenderer';
import { extractSpecialOffers } from '../../components/EkiContentRenderer/constants';
import { hasCookie, getCookie, setCookie } from 'Utils/index';
import queryString from 'query-string';
import { withUrlParams } from 'Utils/withUrlParams';

// enhance the original axios adapter with cache enhancer
const http = axios.create({
  baseURL: '/',
  headers: { 'Cache-Control': 'no-cache' },
});

// Create the instance of the MainStore. The MainStore is a simple key/value store, which is passed
// to every generic content component to allow access to cached values.
const mainStore = new MainStore();

class GenericContainer extends React.Component {
  static propTypes = {
    entry: PropTypes.object,
    dealer: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object,
    onContentLoad: PropTypes.func,
    onContentLoaded: PropTypes.func,
    onOpenContact: PropTypes.func,
    onOpenConfigurator: PropTypes.func,
    noCache: PropTypes.bool,
    sharedState: PropTypes.object,
    setSharedState: PropTypes.func,
  };

  state = {
    content: [],
    contentLoaded: false,
    pageId: null,
    status: 200,
  };

  componentDidMount() {
    this.loadContent(this.props);
  }

  componentDidUpdate(prevProps, prevState) {
    const anchorId = this.props.location.hash;

    // jump to anchor element after initial render
    if (
      prevState.contentLoaded !== this.state.contentLoaded &&
      anchorId &&
      !anchorId.startsWith('#highlights')
    ) {
      try {
        window.scrollTo(0, document.querySelector(`${anchorId}`).offsetTop);
      } catch (e) {
        console.warn('page anchor failed', e);
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextprops) {
    if (nextprops.match.url !== this.props.match.url) {
      this.setState({ contentLoaded: false });
      this.loadContent(nextprops);
    }
  }

  loadContent = (props) => {
    const { params } = props.match;
    const hasStatcookie = hasCookie('privacySettings')
      ? JSON.parse(getCookie('privacySettings'))
      : false;

    // pass ALL query params to backend by default
    const queryParams = parse(props.location.search.replace('?', ''));

    let campaignCode;
    const queries = queryString.parse(props.location.search);
    const someCampaignCode = Object.keys(queries).find(
      (query) => query.toLowerCase() === 'campaigncode'
    );

    if (someCampaignCode) {
      campaignCode = queries[someCampaignCode];
    }

    (() => {
      hasStatcookie &&
        hasStatcookie.cookieTypes.performance &&
        campaignCode &&
        setCookie('campaignCode', campaignCode);
    })();

    const hasCampaignCookie = hasCookie('campaignCode') ? getCookie('campaignCode') : false;

    if (params.slug) {
      queryParams.slug = params.slug;
    }

    if (params.id) {
      queryParams.idDetail = params.id.split('-')[0];
    }

    // Set title and meta tags
    document.title = `${props.entry.label} - ${props.dealer.name}`;
    document.querySelector('meta[name=description]').content = props.entry.description;

    // trigger load event to temporary hide footer
    props.onContentLoad();

    const httpAdapter = this.props.noCache ? axios : http;

    props.setSharedState(null, null, {
      offers: [],
      specialOffers: [],
      campaignCode: null,
    });

    (() => {
      hasStatcookie &&
        hasStatcookie.cookieTypes.performance &&
        hasCampaignCookie &&
        props.setSharedState('campaignCode', hasCampaignCookie);
    })();

    return httpAdapter({
      method: 'get',
      url: `${API_BASE_URL}/pages/${props.entry.id}/`,
      params: queryParams,
    })
      .then((res) => {
        const { routes, pageIdsWithHeaderModeMini } = this.props.dealer;

        const pageId = res.data.id;

        let dokType;
        const currentRoute = routes.find((route) => route.id === pageId);
        if (currentRoute) {
          dokType = currentRoute.dokType;
        }

        props.onContentLoaded({ pageId, dokType });

        this.setState({
          contentLoaded: true,
          content: res.data.content,
          pageId: res.data.id,
          status: 200,
        });

        const specialOffers = extractSpecialOffers(res.data.content);
        const offersElement = res.data.content.find((contentElm) =>
          contentElm.type.includes('plugin_mazdaapi_offers')
        );
        const footnotes_landingpage = res.data.content.find((contentElm) =>
          contentElm.type.includes('plugin_footnotes_landingpage')
        );

        props.setSharedState(null, null, {
          offers: offersElement ? offersElement.offerList : [],
          specialOffers: specialOffers || [],
          footnotes_landingpage: footnotes_landingpage ? footnotes_landingpage.footnotes : [],
          forceMobileMenu: pageIdsWithHeaderModeMini.includes(pageId),
          serviceFootnotes: [],
        });
      })
      .catch((err) => {
        props.onContentLoaded();
        this.setState({
          contentLoaded: true,
          status: err.response ? err.response.status : '???',
        });
      });
  };

  render() {
    const { content, contentLoaded, status } = this.state;
    const { sharedState, setSharedState } = this.props;

    if (!contentLoaded) {
      return null;
    }

    return (
      <EkiContentRenderer
        content={content}
        pageId={this.state.pageId}
        mainStore={mainStore}
        sharedState={sharedState}
        setSharedState={setSharedState}
        status={status}
        scrollToDeeplinkId={this.scrollToDeeplinkId}
        urlParams={this.urlParams}
        {...this.props}
      />
    );
  }
}

export default withRouter(withUrlParams(GenericContainer));

// @flow
import React from 'react';
import styles from './styles.scss';

type Props = {
  content: string,
  hasDarkBackground?: Boolean,
};
export default class RichText extends React.Component<Props> {
  render() {
    const { content, hasDarkBackground } = this.props;

    return (
      <div
        className={styles.root}
        dangerouslySetInnerHTML={{ __html: content }}
        data-has-dark-background={hasDarkBackground}
      />
    );
  }
}

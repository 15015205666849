import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';
import classNames from 'classnames/bind';
import cn from 'classnames';

const cx = classNames.bind(styles);

export default class Accordion extends React.Component {
  static propTypes = {
    open: PropTypes.bool,
    label: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    collapsible: PropTypes.oneOf(['m', 'l', 'xl']),
    small: PropTypes.bool,
    single: PropTypes.bool,
    resetBorderTop: PropTypes.bool,
    extendedContainerHeight: PropTypes.bool,
    outerControlToggle: PropTypes.func,
    outerControlOpen: PropTypes.bool,
    accordionName: PropTypes.string,
    e2e: PropTypes.string,
  };

  static defaultProps = {
    open: false,
    small: false,
    single: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
    };
  }

  toggle = () => {
    const { outerControlToggle, accordionName } = this.props;

    if (outerControlToggle) {
      outerControlToggle(accordionName);
    } else {
      this.setState({
        open: !this.state.open,
      });
    }
  };

  render() {
    const {
      label,
      children,
      collapsible,
      small,
      single,
      resetBorderTop,
      className,
      extendedContainerHeight,
      outerControlToggle,
      outerControlOpen,
      classNames,
      e2e,
    } = this.props;

    const open = outerControlToggle ? outerControlOpen : this.state.open;

    const rootClass = cx({
      root: true,
      open: open,
      [`collapsible-${collapsible}`]: !!collapsible,
      'root--small': small,
      'root--single': single,
    });

    return (
      <div
        className={cn(rootClass, className, classNames && classNames.root)}
        data-e2e={e2e}
        data-reset-bordertop={resetBorderTop}
      >
        <div className={cn(styles.header, classNames && classNames.header)} onClick={this.toggle}>
          <span className={cn(styles.label, classNames && classNames.label)}>{label}</span>
          <ul className={styles.icon}>
            <li className={open ? styles.opened : null} />
            <li className={open ? styles.opened : null} />
          </ul>
        </div>
        <div
          className={cn(styles.body, classNames && classNames.customTransiton)}
          data-extended-height={extendedContainerHeight}
        >
          <div>{children}</div>
        </div>
      </div>
    );
  }
}

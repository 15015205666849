import React from 'react';

const GlobalContext = React.createContext({
  globalContext: {
    selectedAccessoryPart: {},
  },
  updateGlobalContext: () => {},
});

export default GlobalContext;

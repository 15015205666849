// @flow

export default class MainStore {
  stores: { [string]: Object } = {};

  has = (storeName: string) => {
    return this.stores.hasOwnProperty(storeName);
  };

  get = (storeName: string): any => {
    return this.stores[storeName];
  };

  set = (storeName: string, data: any) => {
    return (this.stores[storeName] = data);
  };
}

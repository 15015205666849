import React from 'react';
import ErrorPage from '../../containers/ErrorPage';
import { Container } from '../Grid';
import styles from './styles.scss';

export default class ErrorBoundary extends React.Component {
  state = {
    error: null,
  };

  componentDidCatch(error: Error, errorInfo): void {
    this.setState({ error, errorInfo });
  }

  render() {
    const { error, errorInfo } = this.state;
    if (error) {
      return (
        <ErrorPage
          title="Es ist ein allgemeiner Fehler aufgetreten"
          details="Bitte gehen Sie eine Seite zurück und versuchen Sie es erneut."
        >
          <Container>
            <details className={styles.details}>
              {error.toString}
              <br />
              {errorInfo.componentStack}
            </details>
          </Container>
        </ErrorPage>
      );
    }

    return this.props.children || null;
  }
}

// @flow
import React from 'react';
import styles from './styles.scss';
import type FootnoteModel from 'Stores/FootnoteModel';

type Props = {
  footnotes: Array<Object>,
};

export default class FootnoteList extends React.Component<Props> {
  render() {
    return this.props.footnotes.map((note: FootnoteModel, i: number) => {
      let indicator: number | string = note.number || i + 1;

      if (note.isGeneric && !note.number) {
        indicator = '';
      }

      return (
        <div key={note.id} className={styles.footnote}>
          <sup>{indicator}</sup>
          <div dangerouslySetInnerHTML={{ __html: note.text }} />
        </div>
      );
    });
  }
}

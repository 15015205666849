// @flow
import axios from 'axios';
import BaseModel from 'Stores/BaseModel';
import AssetModel from 'Stores/AssetModel';
import ListModel from 'Stores/ListModel';
import {
  validateLength,
  validateImageSelect,
  validateRTELength,
  isInvalid,
} from 'Utils/Validators';

declare var API_BASE_URL: string;

export default class AboutUsModel extends BaseModel {
  id: number = 0;
  title: string = '';
  intro: string = '';
  text: string = '';
  image: ?AssetModel;
  gallery: ListModel;

  constructor(data: Object) {
    super();

    data = data || {};

    this.hydrate(data);
    this.image = data.image ? new AssetModel(data.image) : null;
    this.gallery = new ListModel(data.gallery, AssetModel);
  }

  valid = (fields: Array<string> = ['title', 'intro', 'image', 'text']) => {
    this.errors = {};
    fields.indexOf('title') >= 0 && validateLength(this.errors, 'title', this.title, 1, 80);
    fields.indexOf('intro') >= 0 && validateLength(this.errors, 'intro', this.intro, 1, 120);
    fields.indexOf('image') >= 0 && validateImageSelect(this.errors, 'image', this.image);
    fields.indexOf('text') >= 0 && validateRTELength(this.errors, 'text', this.text, 1, 9999);
    return !isInvalid(this.errors);
  };

  save = (fields: Array<string> = []) => {
    const payload = {
      settings: {
        aboutus: this.toJSON(),
      },
    };

    if (fields.length > 0) {
      payload.settings.aboutus = Object.keys(payload.settings.aboutus)
        .filter(key => fields.indexOf(key) !== -1)
        .reduce(
          (newObj, key) => Object.assign(newObj, { [key]: payload.settings.aboutus[key] }),
          {}
        );
    }

    return axios.patch(`${API_BASE_URL}/dealers/`, payload).then(res => {
      return new AboutUsModel(res.data);
    });
  };

  isNew = () => {
    return false;
  };

  toJSON = () => {
    return {
      title: this.title,
      intro: this.intro,
      text: this.text,
      image: this.image,
      gallery: this.gallery.items,
    };
  };
}

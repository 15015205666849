// @flow
import React from 'react';
import LinkSectionLink from './link';
import AssetModel from 'Stores/AssetModel';
import classNames from 'classnames/bind';
import styles from './styles.scss';

const cx = classNames.bind(styles);

type Props = {
  items: Array<Object>,
  small?: boolean,
  onOpenContact: () => void,
  background: string,
  hasForm?: string,
  deeplinkId?: number,
};

export class LinkSection extends React.Component<Props> {
  static defaultProps = {
    items: [{}],
    background: 'white',
  };

  render() {
    const { items = [], small, background, onOpenContact, hasForm } = this.props;

    const rootClass = cx('section', {
      small,
      [background]: true,
    });

    const linkSectionItems = items.reduce((acc, item) => {
      if (item.hidden) {
        return acc;
      }

      return [...acc, { ...item, image: new AssetModel(item.image) }];
    }, []);

    if (linkSectionItems.length === 0) {
      return null;
    }

    const showInTwoLines = linkSectionItems.length > 4;

    const indexOfNewLineStart = () => {
      if (showInTwoLines) {
        return Math.ceil(linkSectionItems.length / 2);
      }

      return linkSectionItems.length;
    };

    return (
      <div>
        <div className={rootClass}>
          {linkSectionItems.slice(0, indexOfNewLineStart()).map((item, i) => (
            <LinkSectionLink key={i} {...item} onOpenContact={onOpenContact} hasForm={hasForm} />
          ))}
        </div>

        {showInTwoLines && (
          <div className={rootClass}>
            {linkSectionItems
              .slice(indexOfNewLineStart(), linkSectionItems.length)
              .map((item, i) => (
                <LinkSectionLink
                  key={i}
                  {...item}
                  onOpenContact={onOpenContact}
                  hasForm={hasForm}
                />
              ))}
          </div>
        )}
      </div>
    );
  }
}

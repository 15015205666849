import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { HashLink as Link } from 'react-router-hash-link';
import { injectUrlVars } from 'Utils/Validators';
import Icon from 'Components/Icon';
import styles from './styles.scss';
import classNames from 'classnames/bind';
import { gaPush } from 'Utils/ga_push';
import { isExternalLink, validButtonTypes, isValidTarget, purifyHtmlToReact } from 'Utils';
import { isSpecialTracked } from 'Utils/trackingAttributes';

const cx = classNames.bind(styles);

export default class Button extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    href: PropTypes.string,
    additional: PropTypes.string,
    icon: PropTypes.string,
    target: PropTypes.string,
    small: PropTypes.bool,
    big: PropTypes.bool,
    disabled: PropTypes.bool,
    light: PropTypes.bool,
    outline: PropTypes.bool,
    type: PropTypes.oneOf(validButtonTypes),
    onClick: PropTypes.func,
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,
    track: PropTypes.bool,
    trackingPushCode: PropTypes.string,
    className: PropTypes.string,
    additionalProps: PropTypes.object,
    e2e: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    type: 'primary',
    target: null,
    label: '',
  };

  onClick = (event) => {
    this.track(event);
    const { onClick, type, disabled } = this.props;

    if (!disabled && onClick) {
      // events only are necessary for secondaries since we need to prevent the link-follow
      // if we don't drop the event for primaries f.e. proxies are passed and this causes
      // crashes in random spots of the project which rely somehow on event = undefined :|
      onClick(type === 'secondary' ? event : undefined);
    }
  };

  onMouseOver = () => {
    if (!this.props.disabled && this.props.onMouseOver) {
      this.props.onMouseOver();
    }
  };

  onMouseOut = () => {
    if (!this.props.disabled && this.props.onMouseOut) {
      this.props.onMouseOut();
    }
  };

  track = (event) => {
    if (isSpecialTracked(event.target)) {
      return;
    }

    // hotfix track only if Versicherung button clicked
    if (this.props.track) {
      gaPush({
        event: 'gaEvent',
        category: 'CTA',
        action: 'button click',
        label: 'Versicherung scroll to Form',
      });
    }

    if (this.props.trackingPushCode) {
      gaPush({
        event: 'gaEvent',
        category: 'CTA',
        action: 'button click',
        label: this.props.trackingPushCode,
      });
    }
  };

  renderLabel = (type, rawLabel) => {
    const label = purifyHtmlToReact(rawLabel);

    return type === 'primary' ? (
      <div className={styles.button_rolloverwrapper}>
        <span className={styles.button_rollovertext}>
          {label}
          <span className={styles.button_rollovertext_1}>{label}</span>
          <span className={styles.button_rollovertext_2}>{label}</span>
        </span>
      </div>
    ) : (
      <span>{label}</span>
    );
  };

  render() {
    const {
      target,
      type,
      label,
      disabled,
      light,
      small,
      resetLineHeight,
      outline,
      icon,
      big,
      className,
      additional,
      trackingLabel,
      trackingAction,
      id,
      additionalProps,
      e2e,
    } = this.props;

    let href = this.props.href;

    if (
      window.dealer.settings.customTopCars.isActive &&
      href &&
      href.includes('beratung-und-kauf/fahrzeugboerse')
    ) {
      href = '/?modaltype=TOPCARS&showmodal=true';
    }

    const imageButton = label.indexOf('/fileadmin/') !== -1;
    const btnClass = cx(
      'btn',
      `btn--${type}`,
      {
        'btn--disabled': disabled,
        'btn--light': light,
        'btn--small': small || (icon && !big),
        'btn--outline': outline,
        'btn--icon': icon,
        'btn--image': imageButton,
        'btn--reset-line-height': resetLineHeight,
      },
      className
    );
    const inlineIcon = type === 'tertiary';

    if (href) {
      const isExternal = isExternalLink(href);
      const extraProps = {};
      let Tag = Link;

      // external links use anchor tags, href, ...
      if (isExternal) {
        Tag = 'a';
        extraProps.target = isValidTarget(target) ? target : '_blank';
        extraProps.rel = 'noopener noreferrer';
        extraProps.href = injectUrlVars(href + (additional || ''));
      } else {
        extraProps.to = injectUrlVars(href + (additional || ''));

        // default target is _self aka same page so we only set a target if explicitly set + valid
        if (isValidTarget(target)) {
          extraProps.target = target;
        }
      }

      return (
        <Tag
          className={cx('cta', btnClass)}
          onMouseOver={this.onMouseOver}
          onMouseOut={this.onMouseOut}
          onClick={this.onClick}
          data-tracking-label={trackingLabel || label.toLowerCase()}
          data-track-action={trackingAction}
          {...extraProps}
          {...additionalProps}
        >
          {!imageButton && (
            <Fragment>
              {icon && <Icon name={icon} inline={inlineIcon} />}
              {this.renderLabel(type, label)}
            </Fragment>
          )}
          {imageButton && [<img src={label} alt="Button" key="Button" />]}
        </Tag>
      );
    }

    return (
      <button
        id={id}
        name={label}
        className={cx('cta', btnClass)}
        onClick={this.onClick}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
        data-tracking-label={trackingLabel || label.toLowerCase()}
        data-track-action={trackingAction}
        data-e2e={e2e}
        disabled={disabled}
        {...additionalProps}
      >
        {icon && <Icon name={icon} inline={inlineIcon} />}
        {this.renderLabel(type, label)}
      </button>
    );
  }
}

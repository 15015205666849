export const tabs = [
  {
    target: 'REQUEST_FOR_PROPOSAL',
    href: 'angebotsanfrage',
  },
  {
    target: 'SERVICE_APPOINTMENT',
    href: 'werkstatttermin',
  },
  {
    target: 'TEST_DRIVE',
    href: 'probefahrt',
  },
  {
    target: 'REQUEST_FOR_CONSULTATION',
    href: 'beratungstermin',
  },
];

export const tabExists = href => {
  const _href = href.replace(/\//gi, '');

  return tabs.some(item => item.href === _href);
};

export const getTabTargetByIndex = index => tabs[index].target;

export const getTabByHref = href => {
  const _href = href.replace(/\//gi, '');
  return tabs.indexOf(tabs.find(item => item.href === _href));
};

export const getTabByTarget = target => tabs.indexOf(tabs.find(item => item.target === target));

export const tabTypes = tabs.map(tab => tab.target);

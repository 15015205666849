import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import Header, { TOGGLE_LOCATION_SWITCH } from 'Components/Header';
import Footer from 'Components/Footer';
import styles from './styles.scss';
import { withUrlParams } from 'Utils/withUrlParams';
import { MODAL_TYPES } from 'Containers/ContactForm/contactFormConstants';

const ShowServiceAppointment = JSON.parse(SERVICE_APPOINTMENT);

class Layout extends React.Component {
  static propTypes = {
    dealer: PropTypes.object,
    menu: PropTypes.array,
    footer: PropTypes.bool,
    footerVisible: PropTypes.bool,
    children: PropTypes.node,
    location: PropTypes.object,
    type: PropTypes.oneOf(['eki', 'hi']),
    onOpenContact: PropTypes.func,
    onOpenConfigurator: PropTypes.func,
    refreshPageIfNecessary: PropTypes.func,
    error: PropTypes.bool,
    forceMobileMenu: PropTypes.bool,
    setSharedState: PropTypes.func,
  };

  static defaultProps = {
    footer: true,
    type: 'eki',
    footerVisible: true,
  };

  state = {
    initialized: false,
    showMapClicked: false,
  };

  constructor(props) {
    super(props);
    this.setInternalReferrer('EXTERNAL');
  }

  UNSAFE_componentWillReceiveProps = (props) => {
    if (props.footerVisible && !this.state.initialized) {
      this.setState({ initalized: true });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      // Scroll to top on every route change (but not on query param changes)
      window.scrollTo(0, 0);
      this.setInternalReferrer(prevProps.location.pathname);
      this.props.refreshPageIfNecessary();
    }
  }

  setInternalReferrer = (ref) => {
    sessionStorage.setItem('ref', ref);
  };

  setShowMapClicked = () => {
    this.setState({ showMapClicked: true });
  };

  getTextForServiceMenu = () => {
    const { dealer } = this.props;

    if (dealer.settings.salesPartnerOnly)
      return 'Sie möchten einen Beratungstermin? Jetzt einfach hier anfragen.';

    if (dealer.settings.isOsbUser) {
      return 'Sie möchten einen Werkstatttermin? Jetzt einfach hier buchen.';
    } else {
      return 'Sie möchten einen Werkstatttermin? Jetzt einfach hier anfragen.';
    }
  };

  render() {
    const { dealer, menu, type, footer, footerVisible, error, sharedState, setSharedState } =
      this.props;
    const rootClassName = classNames(styles.layout, {
      // Set when generic has been content for the first time (used to allow lazy loading of google
      // map in footer)
      [styles.initalized]: this.state.initalized,
    });

    return (
      <div className={rootClassName}>
        <Header
          dealer={dealer}
          teaser={
            ShowServiceAppointment === true
              ? {
                  // Beratung und Kauf
                  39: dealer.settings.servicePartnerOnly
                    ? {
                        text: dealer.settings.isOsbUser
                          ? 'Sie möchten einen Werkstatttermin? Jetzt einfach hier buchen.'
                          : 'Sie möchten einen Werkstatttermin? Jetzt einfach hier anfragen.',
                        label: 'Werkstatttermin',
                        customTrackingLabel: dealer.settings.isOsbUser
                          ? 'Termin buchen'
                          : 'Termin vereinbaren',
                        onClick: () => {
                          this.props.updateMultipleUrlQuery({
                            modaltype: MODAL_TYPES.FLYOUT_WERKSTATTTERMIN.name,
                            showmodal: 'true',
                          });
                        },
                      }
                    : {
                        text: 'Sie möchten eine Probefahrt? Jetzt einfach hier anfragen.',
                        label: 'Probefahrttermin',
                        onClick: () => {
                          this.props.updateMultipleUrlQuery({
                            modaltype: MODAL_TYPES.TEST_DRIVE.name,
                            showmodal: 'true',
                          });
                        },
                      },
                  // Service
                  4: {
                    text: this.getTextForServiceMenu(),
                    label: dealer.settings.salesPartnerOnly ? 'Beratungstermin' : 'Werkstatttermin',
                    customTrackingLabel: dealer.settings.isOsbUser
                      ? 'Termin buchen'
                      : 'Termin vereinbaren',
                    onClick: () => {
                      this.props.updateMultipleUrlQuery({
                        modaltype: MODAL_TYPES.FLYOUT_WERKSTATTTERMIN.name,
                        showmodal: 'true',
                      });
                    },
                  },
                  // Über uns
                  5: {
                    text: 'Sie wollen uns kontaktieren? Dann schauen Sie hier.',
                    label: 'Kontakt',
                    onClick: TOGGLE_LOCATION_SWITCH,
                  },
                }
              : null
          }
          menu={menu}
          type={type}
          onOpenContact={this.props.onOpenContact}
          onOpenConfigurator={this.props.onOpenConfigurator}
          showMapClicked={this.state.showMapClicked}
          setShowMapClicked={this.setShowMapClicked}
          forceMobileMenu={this.props.forceMobileMenu}
          error={error}
          sharedState={sharedState}
          setSharedState={setSharedState}
        />
        <main key="main">{this.props.children}</main>
        {footer && (
          <Footer
            key="footer"
            visible={footerVisible}
            {...this.props}
            showMapClicked={this.state.showMapClicked}
            setShowMapClicked={this.setShowMapClicked}
          />
        )}
      </div>
    );
  }
}

export default withRouter(withUrlParams(Layout));

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from 'Components/Button';
import Icon from 'Components/Icon';
import cn from 'classnames';
import { handleKeyDown } from 'Utils/handleKeyDown';

import styles from './styles.scss';
import { gaPush } from 'Utils/ga_push';

export default class ConsumptionData extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isClosed: false, outerToggleControlAvailable: !!this.props.outerToggleIsClosed };
  }

  track = event => {
    const { outerIsClosed } = this.props;
    const { isClosed, outerToggleControlAvailable } = this.state;

    const closed = outerToggleControlAvailable ? outerIsClosed : isClosed;

    gaPush({
      event: 'gaEvent',
      category: 'CTA',
      action: 'consumption click',
      label: closed ? 'open' : 'close',
    });
  };

  toggleIsClosed = event => {
    this.track(event);

    if (this.state.outerToggleControlAvailable) {
      this.props.outerToggleIsClosed();
    } else {
      this.setState({ isClosed: !this.state.isClosed });
    }
  };

  render() {
    const {
      showPlaceholder,
      consumptionText,
      inside,
      compact,
      style,
      deeplinkId,
      colorType,
      onClick,
      actionIcon,
      outerIsClosed,
      smallVersion = false,
    } = this.props;

    let { hideCloseButton } = this.props;
    let { isClosed, outerToggleControlAvailable } = this.state;

    if (!this.props.outerToggleIsClosed && !hideCloseButton) {
      hideCloseButton = true;
      isClosed = false;
    }

    if (
      !showPlaceholder &&
      (!consumptionText.length || !consumptionText.filter(text => !!text).length)
    ) {
      return null;
    }

    const isInTile = inside === 'tile';
    const showIcon = smallVersion ? false : !isInTile;
    const isServicePartnerOnly =
      window.dealer.settings.isServicePartner && !window.dealer.settings.isSalesPartner;

    return (
      <Fragment>
        <div
          className={cn({
            [styles.closed]: outerToggleControlAvailable ? outerIsClosed : isClosed,
            [styles.notClosed]: outerToggleControlAvailable ? !outerIsClosed : !isClosed,
          })}
        >
          {actionIcon === 'close' && (
            <button className={styles.actionbtn} onClick={onClick}>
              <Icon name="close" className={styles.actionbtnIcon} inline={false} />
            </button>
          )}

          {actionIcon === 'eco' && (
            <button className={styles.actionbtn} onClick={onClick}>
              <img
                className={styles.actionbtnImg}
                alt="icon-eco"
                src={`${process.env.ASSET_ENV_HOST}/assets/img/${
                  isInTile ? 'icon_eco_white' : 'icon_eco'
                }.svg`}
              />
            </button>
          )}

          <div
            className={cn(styles.container, {
              [styles.white]: colorType === 'white',
              [styles.grey]: colorType === 'grey',
              [styles.insideOffer]: inside === 'offer',
              [styles.insideTile]: isInTile,
              [styles.showPlaceholder]: showPlaceholder,
              [styles.compact]: compact || consumptionText.length > 1,
              [styles.containerAdditions]: smallVersion,
            })}
            id={deeplinkId}
            style={style}
          >
            {showPlaceholder ? (
              <div className={styles.placeholderWrapper}>
                <div className={styles.placeholderText}>
                  Entdecken Sie unsere aktuellen Angebote
                </div>
                <Button
                  label="Mehr Informationen"
                  href={
                    isServicePartnerOnly
                      ? '/service/werkstatt/aktuelle-aktionen/'
                      : '/beratung-und-kauf/angebote/aktuelle-aktionen/'
                  }
                />
              </div>
            ) : (
              <Fragment>
                {showIcon && (
                  <span className={styles.icon}>
                    <img
                      alt="icon-eco"
                      src={`${process.env.ASSET_ENV_HOST}/assets/img/${
                        isInTile ? 'icon_eco_white' : 'icon_eco'
                      }.svg`}
                    />
                  </span>
                )}
                <div className={styles.textWrapper}>
                  {consumptionText.map((text, index) => (
                    <div
                      className={styles.item}
                      key={index}
                      dangerouslySetInnerHTML={{
                        __html: smallVersion ? text.replace('Verbrauchswerte: ', '') : text,
                      }}
                    />
                  ))}
                </div>
              </Fragment>
            )}
          </div>
        </div>
        {!hideCloseButton && (
          <div className={styles.closeWrapper} data-in-offer={inside === 'offer'}>
            <div
              className={cn(
                smallVersion ? styles.smallCircle : styles.bigCircle,
                styles.closeCircle
              )}
              onClick={this.toggleIsClosed}
              onKeyDown={event => {
                handleKeyDown(event, 'Enter', this.toggleIsClosed);
              }}
              tabIndex={1}
            >
              <div
                className={cn(
                  smallVersion ? styles.smallCloseArrow : styles.bigCloseArrow,
                  styles.closeArrow
                )}
                data-is-closed={outerToggleControlAvailable ? outerIsClosed : isClosed}
              ></div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

ConsumptionData.propTypes = {
  showPlaceholder: PropTypes.bool,
  consumptionText: PropTypes.array,
  inside: PropTypes.oneOf(['tile', 'offer']),
  compact: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
  style: PropTypes.object,
  deeplinkId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClose: PropTypes.func,
  actionIcon: PropTypes.oneOf(['eco', 'close']),
  colorType: PropTypes.oneOf(['white', 'grey']),
  outerToggleIsClosed: PropTypes.func,
  outerIsClosed: PropTypes.bool,
};

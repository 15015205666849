// @flow

export default class BaseModel {
  id: number = 0;
  hidden: boolean = false;

  static getLabelByValue(list: Object[], value: mixed) {
    const entry = list.find(entry => String(entry.value) === String(value));
    return entry ? entry.label : '';
  }

  errors: { [string]: ?string } = {};

  valid = (fields?: Array<string>) => {
    return Object.keys(this.errors).length === 0;
  };

  hydrate = (data: Object) => {
    data = data || {};

    // Hydrate only properties explicit defined in the model
    Object.keys(this).forEach((property: string) => {
      if (data.hasOwnProperty(property)) {
        this[property] = data[property];
      }
    });
  };

  set(property: string, value: mixed): void {
    // MAZ-1457 Workaround for customerBenefitPrice: *set* doesn`t update first level property in Offer
    if (property.indexOf('.') === -1) {
      this[property] = value;
    } else {
      const path = property.split('.');
      this[path[0]][path[1]] = value;
      // e.g. cashPrice.price to cashPricePrice
      property = path[0] + path[1].charAt(0).toUpperCase() + path[1].slice(1);
    }

    // Reset the error message whenever an property changes
    this.errors[property] = null;
  }

  getLabel(name: string, value: mixed) {
    const entry = this.constructor[name].find(entry => entry.value === value);
    return entry ? entry.label : '';
  }

  isNew() {
    return !this.id || this.id === 0;
  }

  toListItem(): Object {
    return {};
  }

  isNewOrHidden() {
    return this.isNew() || this.hidden === true;
  }
}
